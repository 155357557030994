<template>
    <div class="customDialog">
        <van-dialog
            v-model:show="dialogShow"
            :z-index="2002"
            :showConfirmButton="false"
            :show-cancel-button="false"
            :lockScroll="true"
            @close="closeDialog"
            :closeOnClickOverlay="true"
        >
            <img class="imgs" @click="orderPkg" style="width: 100%;" :src="recommendedMeal.indexUrl" />
        </van-dialog>
    </div>
</template>

<script>

import {
  defineComponent,
  reactive,
  toRefs,
  getCurrentInstance,
} from 'vue'
import { Dialog } from 'vant';
export default defineComponent({
    name: 'recommend-packgage-dialog',
    props: {
        dialogShow: {
            type: Boolean,
            default: false,
        },
        recommendedMeal: {
            type: Object,
            default: {}
        }
    },
    components: {
        [Dialog.Component.name]: Dialog.Component,
    },
    setup (props, context) {
        const state = reactive({
            
        })
        const methods = {
            closeDialog () {
                context.emit("setDialogShow", false);
            },
            orderPkg () {
                context.emit("orderPkg", props.recommendedMeal);
            }
        }
        return { 
            ...toRefs(state),
            ...methods,
        }
    }
})
</script>

<style lang="scss" scoped>
@import "@/style/theme/index";

.customDialog{
    
    ::v-deep .van-dialog{
        background: transparent;
    }
    
    ::v-deep .van-dialog__content {
        background: transparent;
        .imgs{
            width: 100%;
        }
    }
}
</style>