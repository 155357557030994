<template>
  <div class="content">
    <!-- 头部信息 -->
    <div class="header_bj">
      <div class="header_title">
        <div class="title-left">
          <img class="head_box_info_img" :src="operatorLogo" alt="" />
          <span v-if="cardInfo.operator == 2">中国移动</span>
          <span v-else-if="cardInfo.operator == 3">中国联通</span>
          <span v-else>中国电信</span>
        </div>
        <div class="title-r">
          <div class="title-box" v-if="[3,4].includes(Number(cardInfo.agentType))" @click="openComplaintUrl()">
            <img alt="logo" src="../../assets/image/afPlatfrom/index_ic_tousu.png" />
            <span style="color: #FFC700">投诉商家</span>
          </div>
          <div class="title-box" @click="toComponent('couponList')">
            <img alt="logo" src="../../assets/image/afPlatfrom/coupon.png" />
            我的券
          </div>
          <div class="title-box" v-if="serviceInfo" @click="onContacts('homePageUrc')">
            <img alt="logo" src="../../assets/image/afPlatfrom/service.png" />
            客服
          </div>
        </div>
      </div>
      <div class="header_box">
        <div class="header_box_padding">
          <div class="header_iccid">
            流量卡号：<span>{{
              cardInfo.iccid ? cardInfo.iccid : cardInfo.sim
            }}</span>
          </div>
          <div class="header_name">
            备注名称：<span v-if="Boolean(cardInfo.nickname)">{{
              cardInfo.nickname
            }}</span>
            <span v-else>无昵称</span>
            <van-icon class="icon" @click="show_notice = true" name="edit" />
          </div>

          <div :class="{needRealName: cardInfo.realNameType == 1}" class="header_status">
            <div style="display: flex;">
                <div class="header_status_txt">卡状态：</div>
                <div v-if="cardInfo.statusCode == 3">
                  <span style="color: #f56c6c" v-if="isRechargeFlow && packageStatusTxt">{{ packageStatusTxt }}</span>
                  <span style="color: #f56c6c" v-else>已停机</span>
                </div>
                <div v-else-if="cardInfo.statusCode == 18">
                  <div v-if="cardInfo.operator == 1">
                    <span style="color: #f56c6c">锁卡不能使用, 请解锁</span>
                    <span class="header_status_bnt" @click="deblocking = true">解锁</span>
                  </div>
                  <div v-else>
                    <span style="color: #f56c6c">锁卡不能使用，请联系客服</span>
                  </div>
                </div>
    
                <div v-else-if="cardInfo.statusCode == 1 ||
                  cardInfo.statusCode == 2 ||
                  cardInfo.statusCode == 6 ||
                  cardInfo.statusCode == 11
                  ">
                  <span style="color: #f56c6c" v-if="isRechargeFlow && packageStatusTxt">{{ packageStatusTxt }}</span>
                  <span style="color: #67c23a" v-else>已激活</span>
                </div>            
                <div v-else>
                  <span style="color: #f56c6c">{{ cardInfo.status }}</span>
                </div>            
            </div>
            <template v-if="cardInfo.isRealName == 0 && cardInfo.realNameType == 1">
              <div @click="clickRealNameBtn = true; checkIsNewRealName(2)" style="color: rgb(55, 125, 254);width: 18%;">去实名</div>
            </template>
          </div>
          <div class="header_status"
            v-if="(cardInfo.labels.includes(2) || cardInfo.labels.includes(3)) && [1, 2, 3, 6, 11].includes(cardInfo.statusCode)">
            <div class="header_status_txt">卡余额：</div>
            <div :style="{ color: cardMoney < 10 ? 'red' : '' }">￥{{ cardMoney }}</div>
          </div>
          <!-- v-if="!(cardInfo.labels.includes(2) && (userMoney <= 0 && cardMoney <= 0) && [1, 2, 3, 6, 11].includes(cardInfo.statusCode))" -->
          <div class="header_refresh">
            若连接不上,请点击刷新按钮
            <van-button class="header_refresh_bnt" loading-text="刷新" :loading="false"
              icon="http://zy-iot.oss-cn-shenzhen.aliyuncs.com/www.jhy.com/20221018/450f8b18cb1449aa82a8b62726629b65.png"
              type="info" @click="judge(cardInfo.statusCode, cardInfo.status)">刷新</van-button>           
          </div>
        </div>
      </div>
    </div>
    <!-- 原来的banner,权重低于 配置的 推荐套餐 （开关打开则不显示banner , 只显示推荐套餐 点击购买套餐） -->
    <div class="banner" v-if="recommendedMeal && recommendedMeal.indexBannerUrl">
      <van-swipe :autoplay="0">
        <van-swipe-item @click="orderPkg(recommendedMeal)">
          <img style="border-radius: 16px;" :src="recommendedMeal.indexBannerUrl" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="banner" v-else-if="bannerList.length > 0 && !Boolean(isSimple)">
      <van-swipe :autoplay="bannerInfo.intervalStatus ? bannerInfo.timeInterval * 1000 : 0" indicator-color="white">
        <van-swipe-item v-for="(item, index) in bannerList" :key="index" @click="openUrl(item.url, item.needParams)">
          <img style="border-radius: 16px;" :src="item.imgUrl" />
        </van-swipe-item>
      </van-swipe>
    </div>

    <div class="main">
      <div v-if="Boolean(couponGoodsList.length)" class="product_box">
        <van-collapse v-model="activeNames">
          <van-collapse-item name="1" value="展开">
            <template #title>
              <div class="commodity_title">
                <div class="center">优惠券</div>
              </div>
            </template>
            <!-- 商品列表 -->
            <div class="coupon_goods_list" v-for="(item, index) in couponGoodsList" :key="index"
              :class="{ active: chargeNum == item.price }" @click="pasteHandle(item)">
              <div class="list_content" :class="{
                recommend: Number(item.recommend)
              }">
                <div class="left">{{ item.goodsName }}</div>
                <div class="right">
                  <van-button type="primary" size="small" block>
                    <span style="padding-right: 2px;">
                      ￥
                    </span>
                    <span>
                      {{ item.salePrice }}购
                    </span>
                  </van-button>
                </div>
              </div>
            </div>
          </van-collapse-item>
        </van-collapse>
      </div>
      <template v-if="newBasicPackage.length > 2">
        <div class="tabBox" :style="{ marginTop: bannerList.length > 0 ? '10px' : '20px'}">          
          <div class="[newBasicPackage.length > 2 ? 'width-item' : 'flex-item']"  
          @click="setTab(tag)" 
          v-for="(tag,index) in newBasicPackage" :key="tag.id">          
            <div>
                <span :class="['tagItem',labelId == tag.id ? 'activeTab' : '',]">{{tag.name}}</span>
                <span v-if="index != newBasicPackage.length - 1 " class="separate">|</span>
            </div>
        </div>
        </div>         
      </template>
      <template v-else>
        <div class="title" style="margin-top: 10px;">
          <div>流量充值</div>
          <div v-if="rechargeData.RECHARGE_GUIDANCE_OPEN == '1' && rechargeData.RECHARGE_GUIDANCE_URL"
            class="title_recharge" @click="rechargeGuidanceNav()">为什么充值流量?</div>
        </div>
      </template>
      <div class="notice" v-if="messageList[0]">
        <van-notice-bar left-icon="volume" :scrollable="false" background="#ffffff" color="#FF7D00">
          <van-swipe vertical class="notice-swipe" :autoplay="3000" :touchable="false" :show-indicators="false">
            <van-swipe-item v-for="(item, index) in messageList" :key="index">
              {{ item.mobile }} {{ item.buyTime }}{{ item.packageName }}
            </van-swipe-item>
          </van-swipe>
        </van-notice-bar>
      </div>
      <div
        v-if="!(cardInfo.labels.includes(2) && (userMoney <= 0 && cardMoney <= 0) && [1, 2, 3, 6, 11].includes(cardInfo.statusCode) && cardInfo.isNeedRecharge == 1)">
        <!-- 流量充值列表 -->
        <div class="flowRecharge" v-if="newBasicPackage.length < 3">
          <template v-if="basicDataList[0]">
            <template v-for="(item, index) in basicDataList" :key="index">
              <div class="flowRecharge_box" @click="discountLockPaste(item, 'setMeal', index)" :class="{flowRecharge_box_bg: !item?.pageShowConfig?.packagePicUrl}">
                <div class="flowRecharge_box_item"
                  :style="{ backgroundColor: !item?.pageShowConfig?.packagePicUrl ? item?.pageShowConfig?.packageBgColor + ' !important' : '' }">
                  <div class="flowRecharge_box_img" v-if="item?.pageShowConfig?.packagePicUrl">
                    <img :src="item?.pageShowConfig?.packagePicUrl" alt="">
                  </div>
                  <div v-else class="flowRecharge_padding">
                    <div class="flowRecharge_title">{{ item.packageName }}</div>
                    <div class="flowRecharge_time" v-if="!cardInfo.labels.includes(4)">
                      {{ item.cycle
                      }}
                      {{ cycleCategoryEnum(item.cycleCategory) }}有效期
                    </div>
                    <div class="flowRecharge_price">
                      <div>￥{{ item.price }}</div>
                      <div class="teb_recharge_box_price_original"
                        v-if="showPrice(item) && !item.couponDistributionRecordId">￥{{ item.markPrice }}</div>
                      <div class="coupon-price" v-if="item.couponDistributionRecordId">
                        券后<span>￥{{ item.postCouponPrice }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="count-down" :class="item?.pageShowConfig?.packagePicUrl ? 'positionBottom' : ''"
                    v-if="item.countdown && item.countdown < 86400">
                    距优惠结束：<van-count-down @change="countdownChange($event, index)" :time="item.countdown * 1000"
                      :format="`HH:mm:ss`" class="count-down-child" />
                  </div>
                  <div class="count-down" :class="item?.pageShowConfig?.packagePicUrl ? 'positionBottom' : ''"
                    v-else-if="item.countdown">
                    距优惠结束：<van-count-down @change="countdownChange($event, index)" :time="item.countdown * 1000"
                      :format="`DD天HH:mm:ss`" class="count-down-child" />
                  </div>
                  <div class="count-down" :class="item?.pageShowConfig?.packagePicUrl ? 'positionBottom' : ''"
                    v-else-if="item.countdown === 0">
                    距优惠结束：<van-count-down @change="countdownChange($event, index)" :time="0" :format="`HH:mm:ss`"
                      class="count-down-child" />
                  </div>
                </div>
                <div class="teb_recharge_box_select" v-if="item.recommendDesc">
                  <img src="../../assets/image/yyPlatfrom/hot.png" alt="">
                  <div>{{ item.recommendDesc }}</div>
                </div>
                <div v-if="item?.pageShowConfig?.topRecommendLabel"
                  :class="item.recommendDesc ? 'recommend_active' : 'teb_recharge_box_recommend'"><img
                    src="../../assets/image/yyPlatfrom/recommend.png" alt="">
                  {{ item?.pageShowConfig?.topRecommendLabel }}</div>
              </div>
            </template>

          </template>
          <div v-else class="empty" style="width: 100%">暂无流量套餐~</div>
        </div>
        <div class="flowRecharge" v-else>
          <template v-if="tabList">
            <template v-for="(item, index) in tabList" :key="index">
              <div class="flowRecharge_box" @click="discountLockPaste(item, 'setMeal', index)" :class="{flowRecharge_box_bg: !item?.pageShowConfig?.packagePicUrl}">
                <div class="flowRecharge_box_item"
                  :style="{ backgroundColor: !item?.pageShowConfig?.packagePicUrl ? item?.pageShowConfig?.packageBgColor + ' !important' : '' }">
                  <div class="flowRecharge_box_img" v-if="item?.pageShowConfig?.packagePicUrl">
                    <img :src="item?.pageShowConfig?.packagePicUrl" alt="">
                  </div>
                  <div v-else class="flowRecharge_padding">
                    <div class="flowRecharge_title">{{ item.packageName }}</div>
                    <div class="flowRecharge_time" v-if="!cardInfo.labels.includes(4)">
                      {{ item.cycle
                      }}
                      {{ cycleCategoryEnum(item.cycleCategory) }}有效期
                    </div>
                    <div class="flowRecharge_price">
                      <div>￥{{ item.price }}</div>
                      <div class="teb_recharge_box_price_original"
                        v-if="showPrice(item) && !item.couponDistributionRecordId">￥{{ item.markPrice }}</div>
                      <div class="coupon-price" v-if="item.couponDistributionRecordId">
                        券后<span>￥{{ item.postCouponPrice }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="count-down" :class="item?.pageShowConfig?.packagePicUrl ? 'positionBottom' : ''"
                    v-if="item.countdown && item.countdown < 86400">
                    距优惠结束：<van-count-down @change="countdownChange($event, index)" :time="item.countdown * 1000"
                      :format="`HH:mm:ss`" class="count-down-child" />
                  </div>
                  <div class="count-down" :class="item?.pageShowConfig?.packagePicUrl ? 'positionBottom' : ''"
                    v-else-if="item.countdown">
                    距优惠结束：<van-count-down @change="countdownChange($event, index)" :time="item.countdown * 1000"
                      :format="`DD天HH:mm:ss`" class="count-down-child" />
                  </div>
                  <div class="count-down" :class="item?.pageShowConfig?.packagePicUrl ? 'positionBottom' : ''"
                    v-else-if="item.countdown === 0">
                    距优惠结束：<van-count-down @change="countdownChange($event, index)" :time="0" :format="`HH:mm:ss`"
                      class="count-down-child" />
                  </div>
                </div>
                <div class="teb_recharge_box_select" v-if="item.recommendDesc">
                  <img src="../../assets/image/yyPlatfrom/hot.png" alt="">
                  <div>{{ item.recommendDesc }}</div>
                </div>
                <div v-if="item?.pageShowConfig?.topRecommendLabel"
                  :class="item.recommendDesc ? 'recommend_active' : 'teb_recharge_box_recommend'"><img
                    src="../../assets/image/yyPlatfrom/recommend.png" alt="">
                  {{ item?.pageShowConfig?.topRecommendLabel }}</div>
              </div>
            </template>

          </template>
          <div v-else class="empty" style="width: 100%">暂无流量套餐~</div>
        </div>
        <template v-if="Boolean(alertMsg)">
          <div class="title">
            <div>叠加包 <span class="tips">(生效时间跟随当前生效中的主套餐)</span></div>
          </div>
          <!-- 叠加包购买次数提示 -->
          <div class="empty" style="width: 100%">{{ alertMsg }}</div>
        </template>
        <template v-else>
          <div class="title" v-if="speedDataList[0]">
            <div>叠加包 <span class="tips">(生效时间跟随当前生效中的主套餐)</span></div>
          </div>
        </template>
        <!-- 叠加套餐列表 -->
        <div class="flowRecharge">
          <template v-if="speedDataList[0]">
            <template v-for="(item, index) in speedDataList" :key="index">
              <div class="flowRecharge_box" @click="paste(item, 'superposition', index)">
                <div :style="{ backgroundColor: item?.pageShowConfig?.packageBgColor + ' !important' }"
                  class="flowRecharge_box_item">
                  <div class="flowRecharge_box_img" v-if="item?.pageShowConfig?.packagePicUrl">
                    <img :src="item?.pageShowConfig?.packagePicUrl" alt="">
                  </div>
                  <div v-else class="flowRecharge_padding">
                    <div class="flowRecharge_title">{{ item.packageName }}</div>
                    <div class="flowRecharge_time"></div>
                    <div class="flowRecharge_price">
                      <div>￥{{ item.price }}</div>
                      <div class="teb_recharge_box_price_original"
                        v-if="showPrice(item) && !item.couponDistributionRecordId">￥{{ item.markPrice }}</div>
                      <div class="coupon-price" v-if="item.couponDistributionRecordId">
                        券后<span>￥{{ item.postCouponPrice }}</span>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="hot_recommend" v-if="item?.pageShowConfig?.lowerRightCornerRecommendLabel">
                  {{ item?.pageShowConfig?.lowerRightCornerRecommendLabel }}
                </div> -->
                </div>
                <div class="teb_recharge_box_select" v-if="item.recommendDesc">
                  <img src="../../assets/image/yyPlatfrom/hot.png" alt="">
                  <div>{{ item.recommendDesc }}</div>
                </div>
                <div v-if="item?.pageShowConfig?.topRecommendLabel"
                  :class="item.recommendDesc ? 'recommend_active' : 'teb_recharge_box_recommend'"><img
                    src="../../assets/image/yyPlatfrom/recommend.png" alt="">
                  {{ item?.pageShowConfig?.topRecommendLabel }}</div>
              </div>
            </template>
          </template>
        </div>
      </div>
      <div v-else class="balanceRecharge" @click="navPackageRecharge">
        当前卡片余额不足 <span>立即充值 <van-icon name="arrow" /></span>
      </div>

      <div class="title" style="margin-top: 10px;">
        <div>为什么要充值流量？</div>
      </div>
      <div class="rechargeTraffic">
        4G网络摄像头内置流量卡，通过流量卡把实时监控信息发送到手机，为了及时发现异常，请在体验流量使用到期前及时充值流量套餐，让摄像头更好保护您的安全。
      </div>
      <!-- 当卡片类型是强制缓存且卡片余额为0 卡片不属于卡片转移新卡 则不显示 -->
      <template v-if="!(cardInfo.labels.includes(2) && cardMoney <= 0 && !cardInfo.isChangeCardNewCard)">
        <div class="title" >
          <div>生效中套餐</div>
          <div class="title_details" @click="toComponent('usage-record')">
            <span>套餐详情</span><van-icon name="arrow" />
          </div>
        </div>
        <div class="packgage">
          <template v-if="packageDataList[0]">
            <div v-for="item in packageDataList" :key="item.id">
              <div class="packgage_box" v-if="item.type == 1">
                <div class="packgage_padding">
                  <div class="packgage_title">
                    <div class="packgage_title_text">{{ item.packageName }}</div>
                    <span>已购买套餐</span>
                  </div>
                  <template v-if="!cardInfo.labels.includes(4)">
                      <div class="packgage_day">
                        <span>已用{{ item.effectiveDays }}天 </span>/ 总量{{
                          item.totalDays
                        }}天
                      </div>
                      <div class="packgage_time">
                        有效期：{{ item.effectiveTime.slice(0, 11) }}到
                        {{ item.deadTime.slice(0, 11) }}
                      </div>
                  </template>
                  <div class="packgage_progress" v-if="!cardInfo.labels.includes(4)">
                    <van-progress :percentage="(item.effectiveDays / item.totalDays) * 100" :show-pivot="false"
                      stroke-width="4" color="#377DFE" track-color="rgba(0,0,0,0.1)" />
                  </div>
                </div>
              </div>
              <div class="packgage_box packgage_box_free" v-else-if="item.type == 2">
                <div class="packgage_padding">
                  <div class="packgage_title">
                    <div class="packgage_title_text">{{ item.packageName }}</div>
                    <span>免费{{ cardInfo.labels.includes(1) ? '' : '体验' }}流量</span>
                  </div>
                  <template v-if="!cardInfo.labels.includes(1) && !cardInfo.labels.includes(4)">
                    <div class="packgage_day">
                      <span>已用{{ item.effectiveDays }}天 </span>/ 总量{{
                        item.totalDays
                      }}天
                      <span>（请及时购买套餐）</span>
                    </div>
                    <div class="packgage_time">
                      有效期：{{ item.effectiveTime.slice(0, 11) }}到
                      {{ item.deadTime.slice(0, 11) }}
                    </div>
                    <div class="packgage_progress">
                      <van-progress :show-pivot="false" :percentage="(item.effectiveDays / item.totalDays) * 100"
                        stroke-width="4" color="#F56C6C" track-color="rgba(0,0,0,0.1)" />
                    </div>
                  </template>
                </div>
              </div>
              <div class="packgage_box" v-else>
                <div class="packgage_padding">
                  <div class="packgage_title">
                    <div class="packgage_title_clamp">{{ item.packageName }}</div>
                  </div>
                  <template v-if="!cardInfo.labels.includes(4)">
                    <div class="packgage_day">
                      <span>已用{{ item.effectiveDays }}天 </span>/ 总量{{
                        item.totalDays
                      }}天
                    </div>
                    <div class="packgage_time">
                      有效期：{{ item.effectiveTime.slice(0, 11) }}到
                      {{ item.deadTime.slice(0, 11) }}
                    </div>
                    <div class="packgage_progress">
                      <van-progress :percentage="(item.effectiveDays / item.totalDays) * 100" :show-pivot="false"
                        stroke-width="4" color="#377DFE" track-color="rgba(0,0,0,0.1)" />
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </template>
          <div v-else class="empty">暂无生效中套餐~</div>
        </div>
      </template>
      <div class="title">
        <div>订购记录</div>
        <div class="title_details" @click="
          toComponent('personal-setMeal', {
            cardType: cardInfo.cardType,
            iccid: cardInfo.iccid,
          })
          ">
          <span>账单中心</span><van-icon name="arrow" />
        </div>
      </div>
      <div class="record">
        <template v-if="setMealDataList[0]">
          <template v-for="(item, index) in setMealDataList" :key="item.id">
            <div class="record_box" v-if="Number(item.category) == 1">
              <div class="record_padding">
                <div class="record_border">
                  <div class="record_title">
                    <div>{{ item.packageName }}</div>
                    <template v-if="!cardInfo.labels.includes(4)">
                        <span v-if="item.amount">¥{{ item.amount }}</span>
                        <span v-else>¥{{
                        item.orderAmount == 0
                        ? item.orderAmount
                        : "-" + item.orderAmount
                        }}</span>
                    </template>
                  </div>
                  <div class="record_time">订购时间：{{ item.createTime }}</div>
                </div>
              </div>
            </div>
          </template>
        </template>
        <div v-else class="empty">暂无订购记录~</div>
      </div>
      <div class="teb_logOut" @click="exit">退出登录</div>
    </div>
    <!-- 推荐套餐弹窗 -->
    <recommend-packgage-dialog 
      @setDialogShow="setDialogShow"
      :dialogShow="dialogShow"
      :recommendedMeal="recommendedMeal"
      @orderPkg="orderPkg"
    />
    <!-- 充值弹窗 -->
    <popup-container ref="popupContainerRef" :serviceInfo="serviceInfo" :rechargeData="rechargeData" :title="title" :moneyConfig="moneyConfig"
      :buyMultiplePackages="buyMultiplePackages" :chargeNum="Number(chargeNum)" :setMealObject="setMealObject"
      :userMoney="Number(userMoney)" :cardMoney="Number(cardMoney)"></popup-container>
    <!-- 刷新提示  -->
    <tip-popup v-if="refresh" title="刷新提示" content="如果刷新后，设备还不能使用请关闭监控的电源重新通电重启监控。" @onConfirm="refresh = false"
      buttonName="知道了"></tip-popup>
    <!-- 电信卡解锁  -->
    <tip-popup v-if="deblocking" title="解锁提示" content="此卡是监控设备专用卡，请勿放到其它设备使用，否则会锁卡，解锁后请等待5分钟左右重启监控。"
      @onConfirm="onDeblocking" @onCancel="deblocking = false" buttonName="解锁"></tip-popup>
    <!-- 未实名认证提示的弹窗  -->
    <tip-popup v-if="needRealName" title="实名提示" content="尊敬的客户，根据工信部要求使用需进行实名，未实名将会无法使用，请您尽快完成实名" @onConfirm="checkIsNewRealName(2)"
      @onCancel="onCancel" buttonName="去实名"></tip-popup>

    <!-- 未读公告提示的弹窗 -->
    <van-popup v-model:show="noticeShow" round closeable :close-icon="require(`_ASSETS_/image/popup/closePopup.png`)">
      <div class="notice_popup" v-if="noticeList.length > 0">
        <div class="goauthenticationImg">
          <p class="title">最新通知</p>
          <img src="../../assets/image/popup/broadcast.png" alt="" />
        </div>
        <div class="goauthenticationTitle">{{ noticeList[0].title }}</div>
        <div class="tips_desc">
          <div class="tips_content">{{ noticeList[0].content }}</div>
        </div>
        <div class="viewDetail">
          <van-button class="detail-button" type="primary" @click="toViewDetail(noticeList)">查看详情</van-button>
        </div>
      </div>
    </van-popup>

    <!-- 新卡需要充值 -->
    <tip-popup v-if="isRecharge" title="充值提示" :content="titleTips" @onConfirm="onTopUp" buttonName="去充值"></tip-popup>
    <!-- 生效套餐 -->
    <tip-popup v-if="isEffectPakage" :title="isReceivePackage ? '领取套餐提醒' : '套餐生效提醒'" :content="titlePakage"
      @onConfirm="effectPakage" :buttonName="isReceivePackage ? '立即领取' : '立即生效'"></tip-popup>
    <!-- 换卡提示弹窗 -->
    <ad-change-card-dialog ref="adChangeCardDialog" v-if="isTipsGetCardPopup"></ad-change-card-dialog>
    <!-- 设置昵称 -->
    <van-popup v-model:show="show_notice" round>
      <name-container @show="closeNameHandle"> </name-container>
    </van-popup>

    <!-- 优惠券弹窗 -->
    <platfrom-coupon ref="platfromCoupon"></platfrom-coupon>

    <!-- 客服电话弹窗 -->
    <van-popup v-model:show="contactsShow" round position="bottom">
      <contacts :info="serviceInfo" @previewCodeShow=onPreviewCode @close="contactsShow = false"></contacts>
    </van-popup>
    <!-- 二维码显示 -->
    <van-popup v-model:show="previewCodeShow" round get-container="#app"
      :close-icon="require(`_ASSETS_/image/popup/closePopup.png`)" style="width: 90vw;">
      <div class="popup_box">
        <div class="popup_title">
          <span v-if="previewCode.type == 'wechatQRCode'">请使用微信扫码添加客服</span>
          <span v-if="previewCode.type == 'qqQRCode'">请使用QQ扫码添加客服</span>
        </div>
        <img :src="previewCode.codeImg" />
        <!-- <div class="popup_text">
          请长按二维码保存、识别
        </div> -->
        <van-button type="default" class="popup_box_bnt" @click="previewCodeShow = false">返回</van-button>
      </div>
    </van-popup>

    <!-- 优惠券返佣版本 -->
    <!-- 优惠券面板 -->
    <coupon-container ref="couponNotice" :couponObject="couponObject"></coupon-container>
    <!-- 优惠锁定中遮罩层 -->
    <div v-if="discountLockDialog" class="wrapper" @click.stop @click.prevent>
      <div class="wrapper-box">
        <van-loading type="spinner" color="#1989fa" style="text-align: center;" />
        <div class="wrapper-box-text">
          优惠锁定中...
        </div>
      </div>
    </div>
    <!-- 跳转小程序弹窗 -->   
    <van-popup v-model:show="realNameFlag" round closeable>
        <div class="realNameBox">
            <div class="realNameContent">
                <slot>
                    <div class="realNameTitle">前往小程序实名</div>
                </slot>
                <slot>
                <div class="realNameChoice">
                    <div class="realNameCancel" @click="realNameFlag = false">取消</div>
                    <div class="realNameConfirm">                        
                        <launch-btn v-if="realNameFlag && cardInfo.iccid" :btnText="'去实名'" :iccid="cardInfo.iccid.substring(0,19)"></launch-btn>
                    </div>                   
                </div>
                </slot>
            </div>
        </div>
    </van-popup>
    <!-- 安防绑定手机号弹窗 -->
    <!-- <template v-if="bindPhoneDialog">
      <van-dialog v-model:show="bindPhoneDialog" @cancel="onClose" :showCancelButton="false"
        :showConfirmButton="false" title="即将取消订单，你是想？" show-cancel-button>
        <div class="rechargeDialog">
          <div class="echargeDialog_bnt">
            <div class="pay" @click="RechargePromptDialogClose">继续支付</div>
          </div>
          <div class="echargeDialog_bnt">
            <div class="select" @click="rechargeGuidanceNav()">不清楚为什么要充值</div>
          </div>
          <div class="echargeDialog_bnt">
            <div class="select" @click="onClose">重选套餐</div>
          </div>
        </div>
      </van-dialog>
    </template> -->
  </div>
</template>

<script>
import {
  toRefs,
  reactive,
  onUnmounted,
  onMounted,
  getCurrentInstance,
  computed,
  nextTick
} from 'vue'
import { onBeforeRouteLeave } from 'vue-router'
import {
  queryCardInfo,
  queryCardStatus,
  checkExchangeCardTime,
  diagnosis,
  GetEffectPackage,
  queryCardReal,
  realNameUrl,
  synWhiteList,
  NoticeNum,
  NoticeList,
  msgNoticeInfo,
  getUnreadTicketMessage,
  advertLog,
  queryPackages,
  queryCardPackage,
  queryOrder,
  realNameRecIot,
  getDialogCoupon,
  remindCoupon,
  serviceContact,
  querySysParamValues,
  zyAdBannerList,
  zyAdBannerInfo,
  testCard,
  queryPackageRechargeMessageList,
  pageNoShowIccidFetchRealUrl,
  getconf,
} from '_API_/api.services'
import { baseUrl } from '_API_/zuJiApi'
import { setStore, getStore, removeStore } from '@/utils/store'
import { isDevice } from '@/utils/utils'
import { Dialog } from 'vant'
import { trackSubmit, handleToLowerCase } from '@/utils/track'
import jssdk from '@/assets/js/jssdk.js'
import { getMobileOperatingSystem } from '../../utils/utils'
import recommendPackgageDialog from './recommend-packgage-dialog.vue'

export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
    recommendPackgageDialog: recommendPackgageDialog,
  },
  setup () {
    const state = reactive({
      isFinish: false, // 优惠倒计时结束
      discountLockDialog: false, // 优惠锁定中 弹窗
      bannerList: [], // 广告数组
      bannerInfo: {}, // 广告参数
      show_notice: false, // 修改昵称
      userMoney: '', // 账户余额
      cardMoney: '', // 卡余额
      setMealDataList: [], // 账单 套餐记录
      packageDataList: [], // 套餐详情列表
      basicIndex: 0, // 基础套餐列表选中框
      speedIndex: 0, // 叠加套餐选中框
      alertMsg: '', // 叠加包购买次数提示
      basicDataList: [], // 基础套餐列表     
      speedDataList: [], // 叠加套餐列表
      newBasicPackage: [], // 新基础套餐列表
      tabList: [],
      labelId: 0,
      buyMultiplePackages: 0, // 多套餐购买 0-关闭 1-开启
      title: '', // 弹窗顶部标题
      chargeNum: '', // 选中充值包的金额
      setMealObject: {}, // 套餐对象
      couponObject: {}, // 优惠券对象
      isSupportRecharge: false,
      active: null,
      flowPercent: 0, // 进度条 剩余流量
      voicePercent: 0, // 进度条语音剩余
      isSimple: 0, // 0 常规版 、 1 简版
      platformCode: '', // 平台code 码
      surplus: '', // 剩余流量
      usedFlow: '', // 已用流量
      voiceSurplus: '', // 剩余语音
      cardInfo: { iccid: '', status: '', iccids: [], labels: [] }, // 卡号信息
      isRealName: '', // 获取实名状态  参数说明:0-否 1-全部实名 2-系统实名 3-三方系统实名 4-暂不实名
      noticeShow: false, // 未读公告提示的弹窗展示
      unreadMsgNumber: 0, // 未读客服回复数量
      noticeNum: 0, // 未读公告数量
      noticeList: [], // 未读公告数据源
      isRecharge: false, // 充值展示
      isRechargeFlow: false, // 是否需要充值
      packageStatusTxt: '', // 去充值 文字提示
      isEffectPakage: false, // 生效套餐展示
      titleTips: '', // 充值标题展示
      titlePakage: '', // 生效套餐标题展示
      isReceivePackage: false, // 领取套餐提示
      showVoice: false, // 语音环形图展示
      lineColor: '#fff', // 流量环形图进度条颜色
      lineColor_: '#fff', // 语音环形图进度条颜色
      cardUseAble: true, // 卡使用状态
      changeCardShow: false, // 换卡入口展示
      isShowGetCardAd: false, // 领取卡片轮播广告
      isReceiveCard: false, // 领取卡片入口
      isTipsGetCardPopup: false, // 提示换卡弹窗
      openWorkOrder: 2, // 工单入口展示
      shopUrl: '', // 商城入口展示
      deblocking: false, // 电信卡解锁弹窗
      refresh: false, // 刷新提示
      needRealName: false, // 是否需要实名
      needRealName_: false, // 遮罩层的实名提示
      realNameUrl: '', // 实名跳转链接
      activeRealName: '', // 点击去实名标识
      theme: '', // 主题名称
      adDialogShow: '', // 广告弹窗
      judgePackage: true, // 智能诊断前置条件，检测套餐是否生效
      kefu_URL: '', // 客服URL
      qiWei_kefu: false, // 是否企微客服
      kefu_complaint_URL: '',
      flowStatus: 'all', // 展示流量： used : 已用 /  all ： 剩余
      zhongtian: false,      
      showZujiOrder: window.SITE_CONFIG.showZujiOrder || false,
      // 临时弹窗方案
      promptInfo: {},
      promptDialogShow: false,
      serviceInfo: '',
      contactsShow: false, // 客服电话弹窗
      previewCodeShow: false, // 二维码显示
      previewCode: {}, // 二维码信息
      rechargeData: {
        RECHARGE_GUIDANCE_OPEN: '', // 充值引导开关配置(1: 开启，0：关闭)
        RECHARGE_GUIDANCE_URL: '' // 充值引导链接URL
      }, // 充值引导开关配置
      activeNames: ['1'],
      couponGoodsList: [],
      messageList: [],   
      moneyConfig: [], // 余额充值包数组
      realNameFlag: false,
      isNewCTBossRealName: '',
      passRealNameUrl:"",//通道配置实名链接地址 
      clickRealNameBtn: false, // 文字实名按钮点击
      recommendedMeal: undefined, // 是否有推荐套餐
      dialogShow: false, // 推荐套餐弹窗
      packageInfo: {}, // 推荐套餐弹窗
    })
    // 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance()
    // 路由 this.$router
    var u = navigator.userAgent
    state.zhongtian = u.indexOf('zhongtian') > -1
    const { $tool: tool, $router: router, $store: store, $http: http } = ctx

    const methods = {
      setDialogShow (boolData) {
        state.dialogShow = boolData
      },
      // 推荐套餐点击上报
      orderBuriedPointer (id) {
        http.post(`/zyPackageRecommend/increment/${id}`)
          .then(({ data: res }) => {
            if (res.code == 0) {
              console.log('点击了推荐套餐')
            }
          })
      },
      // 订购套餐 （这里只管基础包）
      orderPkg (recommendInfo) {
        const {id, packageId} = recommendInfo;
        // 获取套餐信息 （单独获取？）
        methods.orderBuriedPointer(id)
        // 获取套餐详情信息(用于拉起支付)
        const packageData = state.basicDataList.filter(item => item.packageId == packageId)
        if (packageData.length > 0) {
          state.packageInfo = {
            ...packageData[0],
            recommendedOrder: 1, // 推荐套餐支付需要 单独使用字段
          }
          methods.discountLockPaste(state.packageInfo, 'setMeal', -1)
        }
        // try {
        //   http.post(`/zypackage/info/${packageId}`)
        //     .then(({ data: res }) => {
        //       if (res.code == 0) {
        //         // 获取套餐数据
        //         state.packageInfo = {
        //           ...res.zyPackage,
        //           recommendedOrder: 1, // 推荐套餐支付需要 单独使用字段
        //         }
        //         // 打开支付抽屉
        //         methods.discountLockPaste(state.packageInfo, 'setMeal', -1)
        //         // 关闭弹窗
        //         state.dialogShow = false
        //       }
        //     })
        // } catch (e) {
        //   console.log(e)
        // }
      },
      rechargeGuidanceNav () {
        trackSubmit({ eventCode: handleToLowerCase(state.theme) + '_recharge_guide_page' })
        router.push({
          name: 'recharge-guidance',
          query: { ...ctx.rechargeData }
        })
      },
      onPreviewCode (previewCode) {
        ctx.previewCode = previewCode
        ctx.previewCodeShow = true
      },
      onContacts (type) { // 客服 type: home_page_URC 首页右上角   confirm_order_URC 确认订单弹窗右上角
        trackSubmit({
          eventCode: handleToLowerCase(state.theme) + 'CustomerServiceClick',
          entrance: type
        })
        ctx.contactsShow = true
      },
      // 修改昵称
      closeNameHandle (msg) {
        if (msg.nickname === '' || (msg.nickname !== '' && !msg.submit_show)) {
          state.show_notice = msg.show_notice
          return
        }
        if (msg.nickname !== '' && msg.submit_show) {
          state.show_notice = msg.show_notice
          state.cardInfo.nickname = msg.nickname
        }
      },
      onChangeActive (e) {
        // teb 切换下标
        state.active = e.name
        sessionStorage.setItem('ActiveIndex', e.name)
      },
      onTopUp () {
        if (state.cardInfo.labels.includes(2) && (state.cardInfo.userMoney <= 0 && state.cardInfo.cardMoney <= 0)) {
          router.push({
            name: 'personal-packageRecharge'
          })
          return
        } else {
          router.push({
            name: 'af-platfrom',
            query: {
              isRefreshPopup: 1
            }
          })
          return
        }
      },
      cycleCategoryEnum (val) {
        switch (val) {
          case 1:
            return '天'
          case 2:
            return '个月'
          case 3:
            return '个月'
          case 4:
            return '年(12月)'
          case 5:
            return '年(360天)'
          default:
            return '天'
        }
      },
      // 价格选择性展示
      showPrice (item) {
        if (item.markPrice > 0 && item.markPrice > item.price) {
          return true
        } else {
          return false
        }
      },
      categoryEnum (val) {
        switch (val) {
          case 1:
            return '天'
          case 2:
            return '月'
          case 3:
            return '联通月'
          case 4:
            return '年(12月)'
          case 5:
            return '年(360天)'
          default:
            return '天'
        }
      },
      discountLockPaste (item, type, index) {
        if (item.countdown) {
          state.discountLockDialog = true
          setTimeout(() => {
            state.discountLockDialog = false
            ctx.paste(item, type, index)
          }, 1500)
        } else {
          ctx.paste(item, type, index)
        }
      },
      // 选中充值包的方法
      paste (item, type, index) {
        console.log({item, type, index})
        trackSubmit({
          eventCode: handleToLowerCase(state.theme) + '_select_package_click',
          packageId: item.packageId,
          packageName: item.packageName,
          packageCategory: item.category,
          packageType: item.packageType,
          packageCycle: item.cycle,
          packageCycleType: methods.categoryEnum(item.cycleCategory),
          packageAmount: item.price,
          packageSort: item.sort,
          isRecommend: item.recommend,
          recommendWords: item.recommendDesc
        })
        switch (type) {
          case 'setMeal':
            console.log('0')
            state.basicIndex = index
            console.log('1')
            state.title = '确认订单'
            state.chargeNum = Number(item.price)
            state.setMealObject = item
            ctx.$refs.popupContainerRef.actionType = 1
            console.log('first')
            nextTick(() => {
              ctx.$refs.popupContainerRef.payInfo.money = Number(item.price) || 0
              ctx.$refs.popupContainerRef.isShow()
              ctx.$refs.popupContainerRef.payInfo.orderType = 'AnFangOrder'
            })
            break
          case 'superposition':
            state.speedIndex = index
            state.title = '确认订单'
            state.chargeNum = Number(item.price)
            state.setMealObject = item
            ctx.$refs.popupContainerRef.actionType = 1
            nextTick(() => {
              if (
                new Date().getTime() >
                new Date(item.cardPackageEndTime).getTime() -
                state.preRechargeDay * 24 * 60 * 60 * 1000 &&
                item.cycleCategory > 3
              ) {
                return tool.confirm(
                  {
                    title: '提示',
                    msg: '离年度结算已经很近，购买叠加包后将会随年度结算一起失效，是否购买？'
                  },
                  () => {
                    ctx.$refs.popupContainerRef.payInfo.money =
                      Number(item.price) || 0
                    ctx.$refs.popupContainerRef.isShow()
                    ctx.$refs.popupContainerRef.payInfo.orderType = 'AnFangOrder'
                  },
                  () => { }
                )
              }
              ctx.getSuperposition(item)
            })
            break
          default:
            break
        }
      },
      // 叠加包弹窗判断
      getSuperposition (item) {
        ctx.$refs.popupContainerRef.payInfo.money = Number(item.price) || 0
        ctx.$refs.popupContainerRef.isShow()
        ctx.$refs.popupContainerRef.payInfo.orderType = 'AnFangOrder'
      },
      toUrl (url) {
        location.href = url
      },
      // 电信卡 确认解锁
      onDeblocking () {
        realNameRecIot({ id: state.cardInfo.id, sim: state.cardInfo.sim }).then(
          ({ data }) => {
            if (data && data.code === 0) {
              tool.toast(
                {
                  msg: '重置成功',
                  duration: 1500
                },
                () => { }
              )
              methods.init()
              state.deblocking = false
            } else {
              tool.toast(
                {
                  msg: '错误提示：' + data.msg,
                  duration: 1500
                },
                () => { }
              )
              state.deblocking = false
            }
          }
        )
      },
      handleLaunchBtn () {
            return new Promise((resolve, reject) => {                    
                if (isDevice() == 'wx') {
                    console.log('sdk配置');
                    // 初始化jssdk的config
                    getconf({
                        url: location.href.split('?')[0],
                    }).then(({ data: res }) => {
                        if (res.code == 0 && !res.data.code) {                    
                            jssdk.config(res.data)      
                            resolve(true)                                                   
                        } else {
                            resolve(false)
                        }
                    })
                }
            })                    
      },
      /**
       * 此方法是为了避免原有业务被改动，在最外层判断新的逻辑
       * 点击实名前 调用接口判断 1. 是否满足三个通道 2. 是否有手机号（无：跳转手机绑定页面） 3. 实名链接
       * @param {*} type  1 页面入口 2. 弹窗实名
       */
       async checkIsNewRealName (type=1) {
        // 联通
        if(state.cardInfo.operator == 3 && isDevice() == 'wx'){
          // 开启一个弹窗前 关闭之前的弹窗
          state.needRealName = false
          state.realNameFlag =  await ctx.handleLaunchBtn()
        }
        if(!state.realNameFlag){
            pageNoShowIccidFetchRealUrl({
              card: state.cardInfo.iccid,
              backUrl: window.location.origin + '/query?iccid=' + state.cardInfo.iccid 
            }).then(({ data }) => {
              // 接口调用成功
              if (data.code == 0) {
                // 不满足条件走原来的逻辑
                if (!data?.data?.isNewTelecomRealName) {
                  if (type == 1) {
                    // 原页面实名
                    ctx.toComponent('personal-authenTication')
                  } else {
                    // 原弹窗实名
                    ctx.toRealName()
                  }
                // 没手机号去绑定
                } else if (data?.data?.isNeedMobile == 1) {
                  console.log('跳转手机号绑定页面')
                  router.push({
                    name: 'personal-noShowIccidBindMobile',
                    // realNameUrl: data.data.realNameUrl
                    query: {
                    //   virtualId: store.state.user.userInfo.virtualId,
                    //   iccid: store.state.user.userInfo.iccid,
                      card: state.cardInfo.iccid
                    }
                  });
                } else if (data.data.realNameUrl) {
                // 满足上述 直接跳转
                  location.href = data.data.realNameUrl
                } else {
                  tool.toast({
                      msg: '未配置实名链接，请联系客服！'
                  });    
                }
                return
              }
              // 错误提示
              tool.toast({
                  msg: data.msg
              });
            });
        }
       },
      // 去实名
    //   onConfirm () {
    //       if (state.activeRealName) {          
    //       ctx.realnameFn(state.cardInfo)
    //       return
    //     }
    //     if (state.needRealName) {            
    //       router.push({
    //         name: 'personal-authenTication'
    //       })
    //       return
    //     }
    //     location.href = `${state.realNameUrl}?card=${state.cardInfo.iccid}`
    //     },
      realLink(){//ct-boss卡实名跳转
        realNameUrl(state.cardInfo.iccid).then(({ data }) => {
            console.log(data)
            if (data.code == 0 && !data.data.passRealNameUrl) {
            location.href = data.data.realNameUrl
            } else if (data.code != 0) {
            tool.toast({
                msg: data.msg
            });
            }
        });
      },
    //去实名
      async toRealName() {
        // 联通
        if(state.cardInfo.operator == 3 && isDevice() == 'wx'){                               
            state.realNameFlag =  await ctx.handleLaunchBtn()
        }
        if(!state.realNameFlag){            
            if(state.cardInfo.newReal == 1 && (state.isRealName == 0 || state.isRealName == 2) && !state.passRealNameUrl){
              //ct-boss卡实名跳转
              methods.realLink()
              return
            }
            if (state.activeRealName) {
              ctx.realnameFn(state.cardInfo);
              return;
            }
            if ((state.needRealName && state.needRealName_) || state.clickRealNameBtn) {
              router.push({
                name: "personal-authenTication"
              });
              return;
            }
            // 判断isNewCTBossRealName字段 
            if(state.isNewCTBossRealName == 1){            
                return location.href = state.realNameUrl
            }
            location.href = `${state.realNameUrl}?card=${state.cardInfo.iccid}`;
        }
      },  
      onCancel () {
        state.needRealName = false
      },
      // 点击未实名弹窗的取消图标 && 遮罩层 触发
      closeRealName () {
        state.needRealName_ = true
      },
      // 公告弹窗
      noticePopup () {
        if (state.noticeNum > 0) {
          // 如果是系统实名 且 通道填写了链接 遮罩层要显示 实名弹窗亦要弹出
          if (state.isRealName == 2 && state.realNameUrl) {
            state.needRealName = true
            state.needRealName_ = true
            return
          }
          if (state.noticeList.lenghth > 0) {
            state.noticeShow = true
          }
        }
      },
      // 状态码弹窗
      warnPopup () {
        return new Promise((resolve) => {
          const conf = tool.getCardStatusText(state.cardInfo.statusCode) // 卡状态配置
          if (conf.router || conf.router_) {
            tool[conf.router ? 'confirm' : 'alert'](
              {
                title: '提示',
                msg: conf.msg,
                cancelButtonText: conf.cancelButtonText,
                confirmButtonText: conf.confirmButtonText,
                confirmButtonColor: tool.getThemeList(state.theme)
              },
              () => {
                if (
                  (conf.router === 'fb-list' || conf.router_ === 'fb-list') &&
                  Boolean(state.kefu_URL)
                ) {
                  return ctx.keFuHandle()
                }
                if (conf.router == 'personal-packageRecharge') return
                router.push({
                  name: conf.router || conf.router_,
                  query: {
                    card: state.cardInfo.iccid,
                    type: 0
                  }
                })
                resolve(false)
              },
              () => {
                resolve(false)
              }
            )
          } else {
            resolve(false)
          }
        })
      },
      // 退出
      exit () {
        store.dispatch('UserLogout')
        removeStore({ name: 'tongLianInfo' })
        removeStore({ name: 'isSimple', type: 'session' })
        removeStore({ name: 'userOpenId', type: 'session' })
        removeStore({ name: 'userBind', type: 'session' })
        removeStore({ name: 'initAd', type: 'session' })
        removeStore({ name: 'isShowGetCardAd', type: 'session' })
        sessionStorage.removeItem('af-index-isShowPopuped')
        sessionStorage.removeItem('ActiveIndex')
        sessionStorage.removeItem('af-home-redirect')
        location.reload()
      },
      typeRealName (realName) {
        realName = Number(realName)
        switch (realName) {
          case 0:
            return '未实名'
          case 5:
            return '部分实名'
          default:
            return '已实名'
        }
      },
      // 投诉商家
      openComplaintUrl () {
        location.href = 'https://ten.sobot.com/ticketclient/index.html?sysNum=2f388dd84ba745e39d7fced13097d4dd&deployId=bd4c128466f94fbbbef3b4a24fbb5c79'
      },
      // 客服链接跳转
      keFuHandle () {
        // 跳转企微客服
        state.qiWei_kefu && (location.href = state.kefu_URL)
        // 跳转智齿客服
        !state.qiWei_kefu &&
          (location.href = `${state.kefu_URL}&params=${JSON.stringify({
            iccid: state.cardInfo.iccid
          })}&customer_fields=${JSON.stringify({
            customField1: state.cardInfo.iccid
          })}`)
      },
      // 路由跳转
      toComponent (name, type) {
        if (name === 'fb-list' && Boolean(state.kefu_URL)) {
          return ctx.keFuHandle()
        }
        if (name === 'personal-changecard') {
          store.dispatch('SetUserInfo', { isPackage: false })
        }
        if (type != '') {
          store.dispatch('SetUserInfo', { isPackage: true })
        }
        router.push({
          name,
          query: type
        })
      },
      // 公告弹窗最新通知 =>查看详情
      toViewDetail (noticeList) {
        msgNoticeInfo({
          msgNoticeId: noticeList[0].id,
          card: state.cardInfo.iccid
        }).then(({ data: res }) => {
          router.push({
            name: 'message-notice',
            query: noticeList[0]
          })
        })
      },
      // 智能诊断=>检测是否有套餐
      judgeSetMeal () {
        const confUsage = tool.getCardStatusUsageText(state.cardInfo.statusCode) // 卡状态配置 未实名 有套餐 用量大于等于90% 提醒文本
        const confNewCard = tool.getCardStatusNewCardText(
          state.cardInfo.statusCode
        ) // 卡状态配置 未实名 无套餐 为新卡 提醒文本
        // 后台配置主动实名或者不实名 则判断是否有套餐 有套餐则判断用量是否大于等于90%

        // 判断语音
        if (
          state.cardInfo.totalVoice !== null &&
          state.cardInfo.totalVoice != 0
        ) {
          var voiceUsage = state.cardInfo.usedVoice / state.cardInfo.totalVoice
        }
        // 判断流量
        if (
          state.cardInfo.totalFlow !== null &&
          state.cardInfo.totalFlow != 0
        ) {
          var flowUsage = state.cardInfo.usedFlow / state.cardInfo.totalFlow
        }
        var bothUsage = false
        // 判断语音 流量 使用均大于等于90%
        if (voiceUsage >= 0.9 && flowUsage >= 0.9) {
          bothUsage = true
        }
        if (voiceUsage >= 0.9 || bothUsage || flowUsage >= 0.9) {
          tool.confirm(
            {
              title: '提示',
              msg: voiceUsage
                ? confUsage.totalVoiceMsg
                : bothUsage
                  ? confUsage.bothMsg
                  : confUsage.totalFlowMsg,
              cancelButtonText: confUsage.cancelButtonText,
              confirmButtonText: confUsage.confirmButtonText,
              confirmButtonColor: tool.getThemeList(state.theme)
            },
            () => {
              if (confUsage.rechargeRouter == 'personal-packageRecharge') { return }
              router.push({
                name: confUsage.rechargeRouter,
                query: {
                  type: 0
                }
              })
            }
          )
        } else {
          // 常规诊断
          router.push({
            name: 'intelligent-diagnosis',
            query: {
              usageCode: state.cardInfo.statusCode
            }
          })
        }
      },
      // 智能诊断=>常规去诊断
      routineJudge () {
        // 已断网 且 非DCP的
        if (state.cardInfo.statusCode == 13 && state.cardInfo.isDCP == 0) {
          // 常规诊断
          router.push({
            name: 'intelligent-diagnosis',
            query: {
              usageCode: state.cardInfo.statusCode
            }
          })
          return
        }

        // 未实名 且 通道设置了强制实名
        if (
          state.cardInfo.isRealName == 0 &&
          state.cardInfo.realNameType == 1
        ) {
          state.needRealName = true
          return
        }
        // 检测是否有套餐
        ctx.judgeSetMeal()
      },
      // 智能诊断=>已断网
      disconnectRealName (type) {
        const confRealName = tool.getCardStatusRealNameText(
          state.cardInfo.statusCode
        ) // 卡状态配置 是DCP 且 已经实名 提醒文本
        if (state.cardInfo.statusCode == 13 && type == 0) {
          // 常规诊断
          ctx.routineJudge()
        } else if (
          state.cardInfo.statusCode == 13 &&
          type == 1 &&
          state.isRealName != 0
        ) {
          tool.confirm(
            {
              title: '提示',
              msg: confRealName.msg_,
              cancelButtonText: confRealName.cancelButtonText,
              confirmButtonText: confRealName.confirmButtonText,
              confirmButtonColor: tool.getThemeList(state.theme)
            },
            () => {
              if (confRealName.router_ == 'personal-packageRecharge') return
              router.push({
                name: confRealName.router_,
                query: {
                  type: 0
                }
              })
            }
          )
        } else if (
          state.cardInfo.statusCode == 13 &&
          type == 1 &&
          state.isRealName == 0
        ) {
          /**
           * @param {type} realNameType 无实名 0 强制实名 1 主动实名 2 被动实名 4
           */
          // 后台配置强制实名 则弹起实名弹窗
          if (state.cardInfo.realNameType == 1) {
            state.needRealName = true
            return
          }
          // 后台配置主动实名或者不实名  则检测是否有套餐
          ctx.judgeSetMeal()
        }
      },
      // 智能诊断
      judge () {
        // 聚合卡 点击智能诊断 若有未生效套餐 则提示生效
        if (state.cardInfo.operator == 4 && state.judgePackage) {
          // 卡诊断
          return ctx.needCharge(state.cardInfo.iccid)
        }

        // 聚合卡 暂不支持智能诊断
        if (state.cardInfo.operator == 4) {
          return tool.toast(
            {
              msg: '该卡暂不支持',
              duration: 1500
            },
            () => { }
          )
        }
        const cardText = tool.getCardStatusType(state.cardInfo.statusCode) // 卡状态类型
        const conf = tool.getCardStatusText(state.cardInfo.statusCode) // 卡状态配置 常规提醒文本
        if (conf.toast) {
          tool[conf.toast]({
            msg: cardText + conf.msg_,
            duration: 3000
          })
        } else if (state.cardInfo.statusCode == 14) {
          // 未实名停机 去实名
          state.needRealName = true
          state.needRealName_ = true
        } else if (
          (state.cardInfo.isDCP == 0 || state.cardInfo.isDCP == 1) &&
          state.cardInfo.statusCode == 13
        ) {
          // 卡片已经断网、请联系客服
          ctx.disconnectRealName(state.cardInfo.isDCP)
        } else if (state.cardInfo.statusCode == 15) {
          // 预注销，去充值
          tool.confirm(
            {
              title: '提示',
              msg: conf.msg,
              cancelButtonText: conf.cancelButtonText,
              confirmButtonText: conf.confirmButtonText,
              confirmButtonColor: tool.getThemeList(state.theme)
            },
            () => {
              if (conf.router == 'personal-packageRecharge') return
              router.push({
                name: conf.router,
                query: {
                  type: 0
                }
              })
            }
          )
        } else if (!state.isEffectPakage && state.judgePackage) {
          // 卡诊断
          ctx.needCharge(state.cardInfo.iccid)
        } else {
          // 常规诊断
          ctx.routineJudge()
        }
      },

      // 检测换卡日期
      exchangeCardParams (iccid) {
        return new Promise((resolve) => {
          checkExchangeCardTime(iccid).then(({ data: res }) => {
            resolve(res)
          })
        })
      },
      navPackageRecharge () { // 跳转到套餐充值页面
        router.push({
          name: 'personal-packageRecharge'
        })
      },
      // 检测卡=>需要重新充值
      needCharge (iccid) {
        if (state.cardInfo.labels.includes(2) && (state.userMoney <= 0 && state.cardMoney <= 0) && [1, 2, 3, 6, 11].includes(state.cardInfo.statusCode) && state.cardInfo.isNeedRecharge == 1) {
          Dialog.confirm({
            title: '重要通知',
            confirmButtonText: '去充值',
            confirmButtonColor: '#3061fd',
            message:
              '当前卡片余额不足，请充值话费后选择套餐以激活使用'
          }).then(() => {
            ctx.navPackageRecharge()
          }).catch(() => {
            // on cancel
          })
          return
        }

        state.changeCardShow = false
        state.isReceivePackage = false
        state.needRealName = false
        diagnosis(iccid, isDevice()).then(async ({ data: res }) => {
          state.isSupportRecharge = true
          trackSubmit({
            eventCode: handleToLowerCase(state.theme) + '_home_page',
            cardStatus: state.cardInfo.statusCode,
            endTime: state.cardInfo.endTime,
            residualFlow: state.cardInfo.residualFlow,
            residualVoice: state.cardInfo.residualVoice,
            realNameStatus: state.cardInfo.isRealName,
            isNewCard: state.cardInfo.isNewCard ? 1 : 0,
            isIneffectivePackage: res.code == 205 ? 1 : 0// 是否有未生效的自带套餐  1 是  0  否
          })
          // 未绑定手机号码
          if (res.code == 201) {
            tool.confirm(
              {
                title: '绑定手机号码提醒',
                msg: '您的卡片还未绑定手机号码，绑定后可以直接输入手机号码查询卡片',
                cancelButtonText: '取消',
                confirmButtonText: '去绑定',
                confirmButtonColor: tool.getThemeList(state.theme)
              },
              () => {
                router.push({
                  name: 'personal-bindMobile',
                  query: {
                    isExchangeCode: res.isExchangeCode || ''
                  }
                })
              }
            )
            return
          }
          // 正常换卡
          if (res.code == 207) {
            const result = await ctx.exchangeCardParams(iccid)
            if (result.msg === 'true') {
              const changeCardData = res.data
              state.changeCardShow = true
              if (changeCardData.status == 2) {
                state.cardUseAble = false
                // 保存换卡数据
                setStore({
                  name: 'changeCardData',
                  content: changeCardData,
                  type: 'session'
                })
                return
              }
              // status 1:未申请 2:已申请 3:已发货 4:置换成功 5:驳回 6:待支付 7:支付中 8:支付失败
              let msg
              const virtualId = state.cardInfo.virtualId
              if (changeCardData.status == 5) {
                msg = `您的卡:${!virtualId ? 'iccid:' + iccid : '充值卡号：' + virtualId
                  }的换卡申请被驳回,请前往查看`
              } else if (
                changeCardData.status == 1 ||
                changeCardData.status == 2 ||
                changeCardData.status == 6 ||
                changeCardData.status == 7
              ) {
                msg = `您的卡:${!virtualId ? 'iccid:' + iccid : '充值卡号：' + virtualId
                  }需要更换卡片,请前往申请换卡`
              } else if (changeCardData.status == 3) {
                msg = `您的卡:${!virtualId ? 'iccid:' + iccid : '充值卡号：' + virtualId
                  }换卡申请已通过,快递${changeCardData.expressCompany + changeCardData.expressNo
                  }`
              }
              tool.alert(
                {
                  msg,
                  title: '重要提示',
                  confirmButtonText: `立即${changeCardData.status == 3 ? '置换' : '申请'
                    }`
                },
                () => {
                  // 保存换卡数据并前往换卡页面
                  setStore({
                    name: 'changeCardData',
                    content: changeCardData,
                    type: 'session'
                  })
                  router.push({
                    name: 'personal-changecard'
                  })
                }
              )
              return
            }
            state.cardUseAble = false
            return tool.toast(
              {
                msg: '换卡时间已过期，请联系客服'
              },
              () => { }
            )
          }
          // 提示换卡
          if (res.code == 208) {
            store.dispatch('SetUserInfo', { isPackage: false })
            if (res.appId) {
              store.dispatch('SetUserInfo', {
                isConfigAppid: true,
                configAppid: res.appId
              })
            }
            const changeCardData = res.data
            state.changeCardShow = true
            state.isShowGetCardAd = false
            state.isReceiveCard = true
            // 检测生效套餐
            state.judgePackage = false
            // 保存换卡数据并前往换卡页面
            setStore({
              name: 'changeCardData',
              content: changeCardData,
              type: 'session'
            })
            // 保存提示换卡标识
            setStore({
              name: 'isShowGetCardAd',
              content: true,
              type: 'session'
            })
            // status 1:未申请 2:已申请 3:已发货 4:置换成功 5:驳回 6:待支付 7:支付中 8:支付失败
            if (changeCardData.status == 1) {
              // 套餐中心显示换卡广告
              setStore({
                name: 'isChangeCardInPackage',
                content: true,
                type: 'session'
              })
              state.isShowGetCardAd = true // 为提示换卡 且 为待申请 则显示轮播换卡广告
              if (state.isSupportRecharge) {
                return ctx.changeCardPopup(res.data) // 为提示换卡 且 为待申请 则显示领取卡片弹窗
              }
            }
            if (changeCardData.status == 4) {
              state.changeCardShow = false // 为提示换卡 且 为置换成功 则不显示领取卡片入口
            }
            let msg
            const virtualId = state.cardInfo.virtualId
            if (changeCardData.status == 2) {
              msg = `您的卡:${!virtualId ? 'iccid:' + iccid : '充值卡号：' + virtualId
                }已提交领卡申请,请前往查看`
            } else if (changeCardData.status == 3) {
              msg = `您的卡:${!virtualId ? 'iccid:' + iccid : '充值卡号：' + virtualId
                }领卡申请已通过,快递${changeCardData.expressCompany + changeCardData.expressNo
                }`
            } else if (changeCardData.status == 5) {
              msg = `您的卡:${!virtualId ? 'iccid:' + iccid : '充值卡号：' + virtualId
                }的领卡申请被驳回,请前往查看`
            } else if (
              changeCardData.status == 6 ||
              changeCardData.status == 8
            ) {
              msg = `您的卡:${!virtualId ? 'iccid:' + iccid : '充值卡号：' + virtualId
                }的领卡申请有待支付费用,请前往支付`
            } else if (changeCardData.status == 7) {
              msg = `您的卡:${!virtualId ? 'iccid:' + iccid : '充值卡号：' + virtualId
                }的领卡申请运费支付中,请前往查看`
            }
            tool.confirm(
              {
                msg,
                title: '重要提示',
                confirmButtonText: '立即前往',
                cancelButtonText: '取消',
                confirmButtonColor: tool.getThemeList(state.theme)
              },
              () => {
                router.push({
                  name: 'personal-changecard'
                })
              }
            )
            return
          }
          // 换imei
          if (res.code == 301) {
            tool.confirm(
              {
                title: '提示',
                msg: res.msg,
                confirmButtonText: '去解绑',
                confirmButtonColor: tool.getThemeList(state.theme)
              },
              () => {
                router.push({
                  name: 'personal-changeImei'
                })
              }
            )
            return
          }
          // 去充值
          if (res.code == 203) {
            // 检测生效套餐
            state.judgePackage = false
            // 停机保号状态不显示充值提示语
            if (state.cardInfo.statusCode == 17) {
              return
            }
            state.isRecharge = true
            state.titleTips = res.msg
            state.isRechargeFlow = true
            if (res.msg == '卡片为新卡，需要充值套餐才可以使用，立即去充值') {
              state.packageStatusTxt = '新卡需充值才可用，请充值流量'
            } else if (res.msg == '套餐已过期，为避免无法使用，请充值套餐') {
              state.packageStatusTxt = '流量已到期，请充值流量'
            } else if (
              res.msg == '套餐即将过期，为避免无法使用，及时充值套餐'
            ) {
              state.packageStatusTxt = '流量即将到期，请充值流量'
            } else if (res.msg == '流量已用完，为避免无法使用，请及时充值') {
              state.packageStatusTxt = '流量已用完，请充值流量'
            } else {
              state.packageStatusTxt = ''
            }
            return
          }
          // 去生效套餐
          if (res.code == 205) {
            state.isEffectPakage = true
            state.titlePakage = res.msg
            return
          }
          // 去领取套餐
          if (res.code == 209) {
            state.isReceivePackage = true
            state.isEffectPakage = true
            state.titlePakage = res.msg
            return
          }
          // 类型为穿戴
          if (res.code == 2099 || res.code == 2100) {
            return ctx.clickChuangDai(res)
          }
          // 异常
          if (res.code == 500) {
            tool.alert({
              title: '提示',
              msg: res.msg
            })
            return
          }
          if (res.code == 0) {
            // 检测生效套餐
            state.judgePackage = false
            // 查询实名认证跳转
            await methods.queryCardRealFn();
            if (state.needRealName) return
          }
          const flag = methods.judgeIsWhitelist()
          // 判断是否有状态弹窗要展示
          if (flag) {
            await ctx.warnPopup()
            // 判断是否要展示公告弹窗
            ctx.noticePopup()
          }
        })
      },
      // 套餐生效
      packageTakeEffect () {
        GetEffectPackage(state.cardInfo.iccid).then(({ data: res }) => {
          if (res.code == 0) {
            if (state.isReceivePackage) {
              tool.toast(
                {
                  msg: '领取套餐成功',
                  duration: 1000
                },
                () => {
                  state.active = 0
                  sessionStorage.setItem('ActiveIndex', state.active)
                }
              )
              return
            }
            tool.toast({
              msg: '生效套餐成功',
              duration: 3000
            })
            ctx.getCardInfo(state.cardInfo)
            return
          }
          tool.toast({
            msg: res.msg,
            duration: 1500
          })
        })
      },
      // 关闭充值弹窗、
      closeHandle (msg) {
        state.isRecharge = msg
      },
      // 关闭生效套餐弹框
      closeHandle_ (msg) {
        state.isEffectPakage = msg
      },
      // 立即生效套餐
      effectPakage () {
        state.isEffectPakage = false
        // 立即生效套餐
        ctx.packageTakeEffect()
      },
      // 实名检测
      queryCardRealFn () {
        /**
         * @param {type} isRealName 0-否 1-全部实名 2-系统实名 3-三方系统实名 4-暂不实名
         */
        return queryCardReal(state.cardInfo.iccid).then(({ data }) => {
          const res = data.hasOwnProperty('data') ? data.data : ''
          if (
            data &&
            data.code == 0 &&
            data.data &&
            data.data.realNameUrl &&
            data.data.isRealName == 0
          ) {
            state.needRealName = true
            state.realNameUrl = data.data.realNameUrl // 保存实名链接

            return
          }
          if (
            data &&
            data.code == 0 &&
            data.data &&
            data.data.hasOwnProperty('activeRealName') &&
            data.data.activeRealName == 1 &&
            data.data.isRealName == 0
          ) {
            state.needRealName = true
            state.activeRealName = data.data.activeRealName // 保存点击实名标识
            return
          }
          if (data.code !== 0) {
            return tool.toast({
              msg: data.msg
            })
          }
        })
      },
      // 获取实名链接
      realnameFn ({ isRealName }) {
        if (Boolean(state.cardInfo.virtualId) && !isRealName) {
          // 跳转到实名中转页
          return router.push({
            name: 'personal-authenTication',
            query: {
              card: state.cardInfo.virtualId
            }
          })
        }
        realNameUrl(state.cardInfo.iccid).then(({ data }) => {
          if (data.code == 0 && data.data.realNameUrl) {
            state.needRealName = false
            tool.alert(
              {
                title: '提示',
                msg: `请记住以下实名卡号 \n ${state.cardInfo.iccid} \n 请长按卡号进行复制，使用时粘贴即可`
              },
              () => {
                location.href = `${data.data.realNameUrl}?card=${state.cardInfo.iccid}`
              }
            )
          } else if (data.code != 0) {
            tool.toast({
              msg: data.msg
            })
          }
        })
      },
      // 亲情号码检测
      judgeIsWhitelist (type) {
        let timeId = null
        if (state.cardInfo.isWhitelist == 0 || type == 2100) {
          tool.confirm(
            {
              title: '提示',
              msg: '温馨提示：只有加入亲情号码才可正常通话使用',
              cancelButtonText: '我知道了',
              confirmButtonText: '立即添加',
              confirmButtonColor: tool.getThemeList(state.theme)
            },
            () => {
              router.push({
                name: 'yyPlatfrom-familyList'
              })
            },
            () => {
              clearInterval(timeId)
            }
          )
          timeId = setTimeout(() => {
            router.push({
              name: 'yyPlatfrom-familyList'
            })
          }, 3000)
          return false
        }
        return true
      },
      // 同步亲情号码
      sycWhiteIpFn () {
        tool.confirm(
          {
            msg: '请确定是否同步亲情号码？',
            confirmButtonText: '立即同步',
            confirmButtonColor: tool.getThemeList(state.theme)
          },
          () => {
            synWhiteList({
              cards: state.cardInfo.iccid
            }).then(({ data }) => {
              if (data.code != 0) {
                const msg = data.msg.replace('白名单', '亲情号码')
                return tool.alert({
                  title: '提示',
                  msg
                })
              }
              return tool.alert(
                {
                  title: '提示',
                  msg: '亲情号码同步成功'
                },
                () => {
                  methods.getCardInfo(store.state.user.userInfo)
                }
              )
            })
          }
        )
      },
      
      // 获取查询的卡号数据
      async getCardInfo (info) {
        await queryCardInfo(info.activeCard || info.iccid, isDevice()).then(
          ({ data: res }) => {
            console.log('------------------')
            if (res.code == 0) {
              state.cardInfo = res.data
              if (!state.cardInfo.labels) {
                state.cardInfo.labels = []
              }
              // 非强制预存 才需要 推荐套餐
              if (!state.cardInfo.labels.includes(2)) {
                methods.fetchRecommondMealData()
              }
              state.cardMoney = res.data.money || 0 // 卡余额
              state.userMoney = res.data.userMoney || 0 // 账户余额
              state.isRealName = res.data.isRealName // 获取实名状态
              // 获取剩余流量
              state.surplus = res.data.residualFlow
              state.usedFlow = res.data.usedFlow
              if (
                res.data.promptInfo &&
                (res.data.promptInfo.promptMsg ||
                  res.data.promptInfo.promptImgUrl)
              ) {
                state.promptDialogShow = true
                state.promptInfo = res.data.promptInfo
              }
              let flowPercent = 1
              let voicePercent = 1
              // 流量环形图绘制必备参数判断
              switch (res.data.totalFlow) {
                case 0:
                case null:
                  const totalFlow = 1
                  flowPercent = 1
                  state.lineColor = '' // 未生效套餐时清空颜色值
                  break
                default:
                  flowPercent = res.data.usedFlow / res.data.totalFlow
                  if (flowPercent >= 0.8 && flowPercent < 0.9) {
                    state.lineColor = 'yellow'
                  }
                  if (flowPercent >= 0.9) {
                    state.lineColor = 'red'
                  }
                  if (flowPercent >= 1) {
                    flowPercent = 1
                  }
                  break
              }
              state.flowPercent = flowPercent * 100
              // 获取剩余语音
              state.voiceSurplus = res.data.residualVoice
              // 语音环形图绘制必备参数判断
              switch (res.data.totalVoice) {
                case null:
                  state.showVoice = false
                  break
                case 0:
                  state.showVoice = true
                  voicePercent = 1
                  state.lineColor_ = '' // 未生效套餐时清空颜色值
                  break
                default:
                  state.showVoice = true
                  voicePercent = res.data.usedVoice / res.data.totalVoice
                  if (voicePercent >= 0.8 && voicePercent < 0.9) {
                    state.lineColor_ = 'yellow'
                  }
                  if (voicePercent >= 0.9) {
                    state.lineColor_ = 'red'
                  }
                  if (voicePercent >= 1) {
                    voicePercent = 1
                  }
                  break
              }
              if (state.showVoice) {
                state.voicePercent = voicePercent
              }
              // 存储用户的其它信息到sessionStorage中
              store.dispatch('SetUserInfo', {
                labels: res.data.labels || [],
                lastAgentId: res.data.lastAgentId,
                agentPath: res.data.agentPath,
                iccid: res.data.iccid,
                virtualId: res.data.virtualId,
                aliAppId: res.data.aliAppId,
                cardId: res.data.cardId,
                iccids: res.data.iccids,
                sim: res.data.sim,
                appid: res.data.AppID,
                wxPayAppid: res.data.wxPayAppid, // 通联appid
                apnId: res.data.apnId,
                money: res.data.money,
                cardMoney: res.data.money,
                userMoney: res.data.userMoney,
                maxWhitelist: res.data.maxWhitelist,
                operator: res.data.operator,
                passagewayType: res.data.passagewayType,
                nickname: res.data.nickname,
                mobile: res.data.mobile,
                totalVoice:
                  res.data.totalVoice !== null ? res.data.totalVoice : '',
                apiCode: res.data.apiCode,
                device: isDevice(),
                promptInfo: res.data.promptInfo,
                paymentWxType: res.data.paymentWxType,
                paymentAliType: res.data.paymentAliType,
                cardType: res.data.cardType,
                agentType: res.data.agentType || ''
              })
              // 安防 补充 手机号登录弹窗 - start
              const isShowPopuped = sessionStorage.getItem('af-index-isShowPopuped')
              if (isShowPopuped != 1 && res.data.passIndustry != 0 && res?.data?.bindMobile == 1 && !res.data.mobile) {
                // 需要绑定手机号
                sessionStorage.setItem('af-index-isShowPopuped', 1)
                console.log('弹出绑定手机号的弹出提示')
                // 弹出弹窗
                return tool.confirm({
                    title: '绑定手机号码',
                    msg: '卡片暂未绑定手机号码，绑定后可享受更多优质服务',
                    cancelButtonText: '取消',
                    confirmButtonText: '确认',
                    confirmButtonColor: tool.getThemeList(state.theme)
                  },
                  () => {
                    router.push({
                      name: 'personal-bindMobile',
                      query: {
                        iccid: res.data.iccid,
                        virtualId: res.data.virtualId,
                        // isExchangeCode: res.isExchangeCode || ''
                      }
                    })
                  }
                )
              }
              // 安防 补充 手机号登录弹窗 - end
              state.openWorkOrder = res.openWorkOrde
              // 卡诊断
              ctx.needCharge(state.cardInfo.iccid)
            }
          }
        )
      },
      // 获取卡号状态
      getCardStatus (info) {
        return new Promise((resolve, reject) => {
          queryCardStatus(info.activeCard || info.iccid).then(({ data: res }) => {
            resolve()
            if (res.code == 55555) {
              Object.assign(state.cardInfo, {
                statusCode: res.code
              })
              return
            }
            if (
              res.code == 55555 ||
              res.data.code == 88 ||
              res.data.code == 7 ||
              res.data.code == 12
            ) {
              state.cardUseAble = false
            }
            if (res.code == 0) {
              Object.assign(state.cardInfo, {
                status: res.data.status,
                statusCode: res.data.code
              })
              return
            }
            tool.toast({
              msg: res.msg,
              duration: 1500
            })
          })
        })
      },
      // 获取未读公告数
      getNoticeNum (params) {
        NoticeNum(params).then(({ data: res }) => {
          if (res.code == 0 && res.data.unreadCount != 0) {
            state.noticeNum = res.data.unreadCount
          }
        })
      },
      // 获取联系客服信息
      getServiceContact (params) {
        state.serviceInfo = ''
        serviceContact(params).then(({ data: res }) => {
          if (res.code == 0 && res.data.contact) {
            if (JSON.parse(res.data.contact)[0]) {
              state.serviceInfo = JSON.parse(res.data.contact)
            }
          }
        })
      },
      // 获取未读公告列表
      getNoticeList (params) {
        NoticeList(params).then(({ data: res }) => {
          if (res.code == 0) {
            state.noticeList = res.data
            return
          }
          tool.toast({
            msg: res.msg,
            duration: 1500
          })
        })
      },
      // 获取未读客服回复数量
      getServiceNum (params) {
        getUnreadTicketMessage(params).then(({ data: res }) => {
          if (res.code == 0) {
            state.unreadMsgNumber = res.unreadMsgNumber
          }
        })
      },
    //   // 查询是否有通道链接
    //   queryRealNameUrl (params) {
    //     realNameUrl(params).then(({ data: res }) => {
    //       // 若已经三要素实名，有通道链接 则保存通道链接
    //       if (
    //         res.code == 0 &&
    //         res.data.realNameStatus == 2 &&
    //         res.data.realNameUrl
    //       ) {
    //         state.realNameUrl = res.data.realNameUrl
    //       }
    //     })
    //   },
      //查询是否有通道链接
      queryRealNameUrl(params) {
        realNameUrl(params).then(({ data: res }) => {
          // 若已经三要素实名，有通道链接 则保存通道链接
          if(res.code == 0 && res.data.passRealNameUrl){
            state.passRealNameUrl = res.data.passRealNameUrl;
          }
          if (
            res.code == 0 &&
            (res.data.realNameStatus == 2 || res.data.realNameStatus == 0) &&
            res.data.realNameUrl
          ) {
            state.realNameUrl = res.data.realNameUrl;
            state.isNewCTBossRealName = res.data.isNewCTBossRealName || ''
            return;
          }
        });
      },
      adDialogClose () {
        // 弹窗广告关闭重新获取卡状态数据
        methods.init()
      },
      // 换卡弹窗控制->当天仅弹出一次
      changeCardPopup (data) {
        const date = new Date()
        const today =
          '当前日期:' +
          date.getFullYear().toString() +
          '/' +
          (date.getMonth() + 1).toString() +
          '/' +
          date.getDate().toString()
        const currentDay = getStore({
          name: `${state.cardInfo.iccid} - changeCardDialog`
        })
        if (!currentDay || currentDay != today) {
          removeStore({ name: `${state.cardInfo.iccid} - changeCardDialog` })
          state.isTipsGetCardPopup = true
          ctx.$nextTick(() => {
            ctx.$refs.adChangeCardDialog.init(data)
          })
          setStore({
            name: `${state.cardInfo.iccid} - changeCardDialog`,
            content: today
          })
        }
      },
      async getBannerList (iccid) {
        const { data } = await zyAdBannerList(1, iccid)
        if (data.code == 0) {
          const stableSorting = (s1, s2) => {
            if (s1.sort < s2.sort) return -1
            return 1
          }
          state.bannerList = data.data.sort(stableSorting)
        } else {
          tool.toast({
            msg: data.msg,
            duration: 1500
          })
        }
      },
      async queryPackageRechargeMessageList (iccid) {
        const { data } = await queryPackageRechargeMessageList({ iccid })
        if (data.code == 0) {
          state.messageList = data.data || []
        } else {
          tool.toast({
            msg: data.msg,
            duration: 1500
          })
        }
      },
      async getBannerInfo () {
        const { data } = await zyAdBannerInfo(1)
        if (data.code == 0) {
          state.bannerInfo = data.data
        } else {
          tool.toast({
            msg: data.msg,
            duration: 1500
          })
        }
      },
      // 广告跳转
      openUrl (url, needParams) {
        // 若有跳转地址 且 需要携带参数 则拼接字段
        if (Boolean(url) && Number(needParams) == 1) {
          location.href =
            url +
            `&channelSource=${state.platformCode}&cardNo=${state.cardInfo.iccid}`
        }
        // 若有跳转地址 且 不需要携带参数 则直接跳转
        if (Boolean(url) && Number(needParams) == 0) {
          location.href = url
        }
      },
        // 检查卡
        checkCard (conf) {    
            return new Promise((resolve) => {                
                testCard({ card: conf.iccid, device: isDevice() }).then(({ data: res}) => {
                    if (res.msg == '该卡不存在') {
                        tool.alert(
                            {
                                title: '提示',
                                msg: res.msg
                            },
                            () => {
                                ctx.exit();
                            }
                        )
                        return resolve(false) 
                    }
                    resolve(true)
                }) 
            })   
        }, 
      async init () {
        // ios 对页面进行一次重定向 - 
        const isRedirect = sessionStorage.getItem('af-home-redirect')
        if (isRedirect != 1 && getMobileOperatingSystem() == 'iOS') {
          sessionStorage.setItem('af-home-redirect', '1')
          location.href = location.origin + '/af-platfrom';
        }
        // 获取客服URL
        state.kefu_URL = window.SITE_CONFIG.kefu_URL
        // 是否企微客服
        state.qiWei_kefu = window.SITE_CONFIG.qiWei_kefu || false
        // 获取用户信息
        const info = store.state.user.userInfo
        console.log({info})
        const isContinueRequest = await ctx.checkCard(info);        
        if(!isContinueRequest) return;
        ctx.getAvailableCouponGoodsList(info.iccid)
        // 优惠券获取
        getDialogCoupon({ iccid: info.iccid }).then(({ data: res }) => {
          if (res.code == 0) {
            const list = res.data || []
            if (list.length > 0) {
              ctx.$nextTick(() => {
                ctx.$refs.platfromCoupon.init(list)
              })
              const couponIds = list.map(v => v.couponId)
              remindCoupon({ iccid: info.iccid, couponIds }).then(({ data: res }) => { })
            }
          }
        })
        //  充值引导开关配置
        ctx.rechargeGuidance(info)
        // 获取账单 套餐记录
        ctx.queryOrder(info.iccid)
        await ctx.getCardStatus(info)
        await ctx.getCardInfo(info)
        // 查询卡状态
        // 获取公告数量
        ctx.getNoticeNum(info.iccid)

        // 获取套餐信息
        ctx.setMeal(info)
        // 获取套餐信息
        ctx.queryCardPackage(info.iccid)
        // 获取客服联系人信息
        ctx.getServiceContact(info.iccid)
        // 获取未读公告列表
        ctx.getNoticeList({
          card: info.iccid,
          page: 1,
          limit: 30
        })
        // 获取未读客服回复数量
        ctx.getServiceNum({
          card: info.iccid
        })
        // 查询是否有通道链接
        ctx.queryRealNameUrl(info.iccid)       
        // 查询广告
        ctx.getBannerList(info.iccid)
        ctx.getBannerInfo()

        ctx.queryPackageRechargeMessageList(info.iccid)

        // 获取商城入口展示
        state.shopUrl = getStore({ name: 'shopUrl' }) || '' // 需咨询
        if (getStore({ name: 'tempSetMeal' })) {
          removeStore({ name: 'tempSetMeal', type: 'session' })
          ctx.toComponent('personal-setMeal', {
            cardType: info.cardType,
            iccid: info.iccid,
            isInvoice: true
          })
        }
      },
      // 获取推荐套餐
      fetchRecommondMealData () {
        // 获取推荐套餐（模态框 + banner）
        const iccid = store.state.user.userInfo.iccid
        try {
          http.post(`/zyPackageRecommend/verifyOrder/${iccid}`).then(({ data: res }) => {
            if (res.code == 0) {
              state.recommendedMeal = res.data || {}
              // 弹窗
              if (state.recommendedMeal.indexUrl) {
                methods.setDialogShow(true)
              }
            }
          })
        } catch (e) {
          console.log(e)
        }
        
      },
      queryOrder (iccid) {
        // 账单 套餐记录
        queryOrder({ type: '1', iccid }).then(({ data: res }) => {
          if (res.code == 0) {
            // state.setMealDataList = res.data; //获取所有数据源
            state.setMealDataList =
              res.data[res.data.length - 1].map.list || '' // 获取最近一个月的数据源
          } else {

          }
        })
      },
      queryCardPackage (iccid) {
        // 获取套餐详情
        queryCardPackage(iccid).then(({ data: res }) => {
          if (res.code == 0) {
            // state.packageDataList = res.data;
            res.data.forEach((item) => {
              if (item.name == '生效中套餐') {
                const filterArr = item.values.flowDetails
                state.packageDataList = filterArr || []
                // state.packageDataList = filterArr.filter(function (n) {
                //   console.log(n.type == 1 || n.type == 2);
                //   if (n.type == 1 || n.type == 2) {
                //     return true;
                //   }
                //   return false;
                // });
              }
            })
            return
          }
          tool.toast(
            {
              msg: res.msg,
              duration: 1500
            },
            () => { }
          )
        })
      },
      // 获取叠加包提前停机天数
      getPreRechargeDay (info) {
        http
          .post('/card/getPreRechargeDay', {
            card: info.virtualId || info.iccid
          })
          .then(({ data: res }) => {
            if (res.code == 0) {
              state.preRechargeDay = res.preRechargeDay
            } else {
              tool.toast(
                {
                  msg: res.msg,
                  duration: 1500
                },
                () => { }
              )
            }
          })
      },
      rechargeGuidance () {
        // 充值引导开关配置 /iot/api/querySysParamValues
        querySysParamValues({ paramKeys: ['RECHARGE_GUIDANCE_OPEN', 'RECHARGE_GUIDANCE_URL'] }).then(({ data: res }) => {
          if (res.code == 0) {
            ctx.rechargeData = res.data
            ctx.$nextTick(() => {
              // state.active = Number(sessionStorage.getItem("ActiveIndex"));
            })
            return
          }
          tool.alert(
            {
              title: '提示',
              msg: res.msg
            },
            () => { }
          )
        })
      },
      // tab切换
    setTab(item){
		if (item.id == state.labelId) return;
	    state.labelId = item.id
        let obj = state.newBasicPackage.find(v => v.id == state.labelId)             
        state.tabList = obj.packageList
    },   
      async setMeal (info) {
        // 查询套餐信息
        await queryPackages({ card: info.iccid }).then(({ data: res }) => {
          if (res.code == 0) {
            state.alertMsg = res.list.alertMsg || ''
            state.basicDataList = res.list.basicPackage || []
            state.speedDataList = res.list.speedPackage || []
            state.newBasicPackage = res.list.newBasicPackage || []            
            if(state.newBasicPackage.length > 2){        
                state.labelId = state.newBasicPackage[0].id
                state.tabList = state.newBasicPackage[0].packageList
            }
            state.buyMultiplePackages = Number(res.list.buyMultiplePackages) // 多套餐购买 0-关闭 1-开启
            const _config = res.list.amountConfig || []
            state.moneyConfig = _config.split('|')
            ctx.$nextTick(() => {
              state.active = Number(sessionStorage.getItem('ActiveIndex'))
            })

            if (state.basicDataList[0]) { // 优惠倒计时功能
              const listMap = state.basicDataList
              listMap.map((item) => {
                if (item.countdown) {
                  const locationTime = getStore({ name: `time-${info.iccid}` })

                  if (locationTime) {
                    if (locationTime.countdown != item.countdown) {
                      locationTime.countdown = item.countdown
                      setStore({
                        name: `time-${info.iccid}`,
                        content: {
                          countdown: item.countdown,
                          startTime: locationTime.startTime
                        },
                        type: false
                      })
                    }
                    const newTime = new Date()// 获取当前时间
                    const { startTime } = locationTime
                    const newStartTime = new Date(startTime)
                    let ranTime = (newTime.getTime() - newStartTime.getTime()) // 当前时间减去首次计算时间(毫秒)
                    ranTime = ranTime / 1000
                    if (ranTime < locationTime.countdown) {
                      // 倒计时时长（秒）-已跑时长（秒）
                      item.countdown = locationTime.countdown - ranTime
                    } else {
                      // 倒计时时长（秒）- [ 已跑时长（秒）mod 倒计时时长（秒）]
                      item.countdown = locationTime.countdown - ctx.mod(ranTime, locationTime.countdown) // 取模
                    }
                  } else {
                    setStore({
                      name: `time-${info.iccid}`,
                      content: {
                        countdown: item.countdown,
                        startTime: new Date() // 获取当前时间
                      },
                      type: false
                    })
                  }
                }
              })
              state.basicDataList = listMap
            }
            return
          }
          tool.alert(
            {
              title: '提示',
              msg: res.msg
            },
            () => { }
          )
        })
        ctx.getPreRechargeDay(info)
      },      
      // 取模
      mod (n, m) {
        return ((n % m) + m) % m
      },
      countdownChange (e, index) {
        ctx.basicDataList[index].countdown = e.total / 1000
      },
      clickAdv () {
        advertLog({
          param: store.state.user.userInfo.iccid,
          type: 17,
          browserType: store.state.user.userInfo.device
        })
        methods.toComponent('personal-changecard')
      },
      // 穿戴类型
      clickChuangDai (res) {
        // 未充值套餐
        if (res.code == 2099) {
          tool.confirm(
            {
              title: '提示',
              msg: res.msg,
              cancelButtonText: '取消',
              confirmButtonText: '去充值',
              confirmButtonColor: tool.getThemeList(state.theme)
            },
            () => {
              state.active = 0
              sessionStorage.setItem('ActiveIndex', state.active)
            },
            () => { }
          )
        }
        // 未添加亲情号码
        if (res.code == 2100) {
          ctx.judgeIsWhitelist(res.code)
        }
      },     
      // 跳转小亿订单
      handleToOrder () {
        const concatParams = `channelSource=${state.platformCode}&cardNo=${state.cardInfo.iccid}`
        location.href = `${baseUrl}/pages/orderListPage/orderListPage?${concatParams}`
      },
      // 跳转小亿优选
      handleToShop () {
        location.href = `${baseUrl}?channelSource=${state.platformCode}&cardNo=${state.cardInfo.iccid}`
      },
      // 获取优惠券数据
      getAvailableCouponGoodsList (iccid) {
        http
          .post('/card/getAvailableCouponGoodsList', { iccid })
          .then(({ data: res }) => {
            if (res.code == 0) {
              state.couponGoodsList = res.data
            } else {
              tool.toast(
                {
                  msg: res.msg,
                  duration: 1500
                },
                () => { }
              )
            }
          })
      },
      // 选中优惠券
      pasteHandle (item) {
        console.log('选中优惠券')
        state.couponObject = item
        nextTick(() => {
          ctx.$refs.couponNotice.isShow()
        })
      }
    }
    // 获取运营商logo 1、电信  2、移动 3、联通 4、聚合卡
    const operatorLogo = computed(() => {
      switch (state.cardInfo.operator) {
        case 1:
          return require('../../assets/image/afPlatfrom/ChinaTelecom.png')
        case 2:
          return require('../../assets/image/afPlatfrom/ChinaMobile.png')
        case 3:
          return require('../../assets/image/afPlatfrom/ChinaUnicom.png')
        case 4:
          return require('../../assets/image/afPlatfrom/ChinaTelecom.png')
        default:
          return require('../../assets/image/afPlatfrom/ChinaTelecom.png')
      }
    })
    onMounted(() => {
      // 获取主题名称
      state.theme = store.state.common.theme
      // 获取平台code 码
      state.platformCode = store.state.common.platformCode
      state.kefu_complaint_URL = window.SITE_CONFIG.kefu_complaint_URL
      state.flowStatus = window.SITE_CONFIG.flowStatus || 'all'
      state.isSimple = store.state.user.isSimple
      removeStore({ name: 'anFangCard', type: 'session' })
      if (router.currentRoute.value.query.isRefreshPopup == 1) {
        state.refresh = true
        router.push({})
      }
      methods.init()
    })
    onBeforeRouteLeave((to, from, next) => {
      if (!state.cardUseAble && to.name == 'fb-list') {
        // 换卡时间已过 可联系客服
        state.changeCardShow = false
        next()
      } else if (state.cardInfo.statusCode == 16 && to.name !== 'fb-list') {
        tool.toast(
          {
            msg: '超量停机状态不可操作，请联系客服',
            duration: 1500
          },
          () => { }
        )
      } else if (state.cardInfo.statusCode == 55555) {
        tool.toast(
          {
            msg: '注销状态不可操作',
            duration: 1500
          },
          () => { }
        )
      } else if (!state.cardUseAble && to.name !== 'personal-changecard') {
        tool.toast(
          {
            msg: '故障卡不可操作',
            duration: 1500
          },
          () => { }
        )
      } else {
        next()
      }
    })
    onUnmounted(() => {
      // 移除支付支付组件
      state.isSupportRecharge = false
      store.dispatch('SetUserInfo', { isPackage: true })
    })
    return { ...toRefs(state), ...methods, operatorLogo }
  }
}
</script>

<style lang="scss" scoped>
@import "@/style/theme/index";

// @include font_color("font_color1");
// @include background_color("background_color1");
.content {
  display: flex;
  flex-direction: column;
  width: 100%;

  .header_bj {
    width: 100%;
    // height: 370px;
    background: linear-gradient(180deg,
        rgba(48, 97, 253, 1) 0%,
        rgba(60, 152, 254, 1) 51%,
        rgba(241, 245, 251, 1) 100%);

    .header_title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 28px;
      height: 64px;
      width: 100%;
      padding: 0px 32px;
      box-sizing: border-box;

      .title-left {
        color: rgba(255, 255, 255, 1);
        font-size: 44px;
        font-weight: 700;
        display: flex;
        align-items: center;

        img {
          width: 60px;
          height: 60px;
        }
      }

      .title-r {
        display: flex;
        align-items: center;
        font-size: 32px;
        color: #fff;
        margin-left: 60px;

        .title-box {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 24px;
          line-height: 32px;
          margin-left: 32px;

          img {
            width: 48px;
            height: 48px;
          }
        }
      }
    }

    .header_box {
      width: 686px;
      position: relative;
      bottom: -16px;
      border-radius: 16px;
      opacity: 1;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0 4px 16px 0 rgba(55, 125, 254, 0.1),
        0 8px 16px 0 rgba(55, 125, 254, 0.1), 0 7px 32px 0 rgba(0, 0, 0, 0.1);
      margin: auto;
      color: rgba(0, 0, 0, 0.8);
      font-size: 36px;

      .header_box_padding {
        padding: 24px 24px;

        .header_name {
          .icon {
            font-size: 36px;
            margin-left: 16px;
          }
        }

        .needRealName{
            display: flex;
            justify-content: space-between
        }
        .header_status {
          margin-top: 16px;
          display: flex;

          .header_status_txt {
            min-width: 150px;
          }

          // justify-content: space-between;
          .header_status_bnt {
            border-radius: 8px;
            line-height: 48px;
            background: linear-gradient(180deg,
                rgba(60, 152, 254, 1) 0%,
                rgba(55, 125, 254, 1) 100%);
            box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1),
              0 8px 16px 0 rgba(55, 125, 254, 0.1),
              0 4px 8px 0 rgba(55, 125, 254, 0.1);
            color: rgba(255, 255, 255, 1);
            font-size: 28px;
            padding: 0px 26px;
            display: inline-block;
            margin-left: 16px;
          }
        }

        .header_refresh {
          padding-top: 24px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-top: rgba(0, 0, 0, 0.1) solid 1px;
          margin-top: 24px;
          line-height: 64px;
          color: rgba(55, 125, 254, 1);
          font-size: 36px;
          font-weight: 700;

          .header_refresh_bnt {
            width: 164px;
            background: linear-gradient(180deg,
                rgba(60, 152, 254, 1) 0%,
                rgba(55, 125, 254, 1) 100%);
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1),
              0 8px 16px 0 rgba(55, 125, 254, 0.1),
              0 4px 8px 0 rgba(55, 125, 254, 0.1);
            padding: 0px;
            height: 64px;
            color: #ffffff;
            border: none;
            font-size: 32px;
            font-weight: 500;
            border-radius: 8px;
          }
        }
      }
    }
  }

  .main {
    width: 92%;
    margin: auto;
    padding-bottom: 32px;
  }

  // 标题
  .title {
    color: rgba(0, 0, 0, 0.8);
    font-size: 36px;
    font-weight: 700;
    line-height: 56px;
    margin-top: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title_recharge {
      color: #377dfe;
      font-size: 28px;
      text-decoration: underline;
    }

    .title_details {
      color: rgba(55, 125, 254, 1);
      font-size: 32px;

      span {
        margin-right: 8px;
      }
    }
  }  
  .tabBox{
    font-size: 36px;   
    display: flex;    
    display: -webkit-box;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling:touch;
    &::-webkit-scrollbar {
    display: none;
    }
    // .width-item {
    //     width: 250px;
    // }
    .flex-item {
        flex: 1;
    }
    .activeTab{        
        font-weight: bold;
    }    
    .separate{
        color: #d1cdcd;
        padding: 0 22px;
        position: relative;
        top: -1px;
    }
  }
  .flowRecharge {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 8px;

    .flowRecharge_box_active {
      background: linear-gradient(-45deg,
          rgba(255, 46, 0, 1) 0%,
          rgba(255, 168, 87, 1) 100%) !important;
      position: relative;
    }
    .flowRecharge_box_bg{
        background: linear-gradient(180deg,
          rgba(55, 125, 254, 1) 0%,
          rgba(55, 125, 254, 1) 100%);
    }
    .flowRecharge_box {
      width: calc(50% - 12px);
      margin-top: 24px;
      border-radius: 16px;      
      position: relative;

      .flowRecharge_box_item {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        position: relative;
        overflow: hidden;
      }

      .flowRecharge_box_img {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          border-radius: 8px;
        }
      }

      .teb_recharge_box_select {
        width: 216px;
        height: 48px;
        padding: 0px 20px;
        font-size: 24px;
        color: #FFE4A6;
        line-height: 48px;
        background: #FE1E14;
        border-radius: 32px 32px 32px 0px;
        position: absolute;
        top: -18px;
        left: -3px;
        display: flex;
        align-items: center;
        z-index: 2;

        div {
          width: 172px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1; //控制显示几行
          -webkit-box-orient: vertical; //webbox方向
        }

        img {
          width: 36px;
          height: 36px;
          margin-right: 6px;
          vertical-align: sub;
        }
      }

      .teb_recharge_box_recommend {
        // width: 92px;
        height: 48px;
        padding: 0px 20px;
        font-size: 24px;
        color: #FFE4A6;
        line-height: 48px;
        background: linear-gradient(92.13deg, #35374F 3.62%, #494E6A 46.22%);
        border-radius: 32px 32px 32px 0px;
        position: absolute;
        top: -18px;
        left: -3px;
        display: flex;
        align-items: center;
        z-index: 1;

        img {
          width: 36px;
          height: 36px;
          margin-right: 6px;
        }
      }

      .recommend_active {
        // width: 92px;
        height: 48px;
        padding: 0px 12px;
        font-size: 24px;
        color: #FFE4A6;
        line-height: 48px;
        background: linear-gradient(92.13deg, #35374F 3.62%, #494E6A 46.22%);
        border-radius: 32px 32px 32px 0px;
        position: absolute;
        top: -16px;
        right: 0px;
        display: flex;
        align-items: center;
        z-index: 1;

        img {
          width: 36px;
          height: 36px;
          margin-right: 6px;
        }
      }

      .hot_recommend {
        width: 250px;
        text-align: center;
        background: #FFE4A6;
        position: absolute;
        right: -84px;
        bottom: 24px;
        color: #ff5201;
        font-size: 24px;
        line-height: 44px;
        transform: rotate(-46deg);
      }

      .flowRecharge_padding {
        padding: 24px 24px 16px 24px;
        color: rgba(255, 255, 255, 1);
        font-size: 32px;
        position: relative;
        overflow: hidden;
        min-height: 220px;

        .flowRecharge_title {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          line-height: 44px;
          min-height: 88px;
        }

        .flowRecharge_time {
          color: rgba(255, 255, 255, 0.6);
          font-size: 28px;
          margin-top: 8px;
          line-height: 40px;
        }

        .flowRecharge_price {
          // display: flex;
          // justify-content: space-between;
          line-height: 48px;
          margin-top: 8px;
          text-align: right;
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          // justify-content: space-between;
          span {
            color: rgba(255, 255, 255, 1);
            font-size: 24px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-align: left;
            max-width: 160px;
          }

          // div {
          //   max-width: 160px;
          // }
        }

        .teb_recharge_box_price_original {
          font-size: 24px;
          color: #fff;
          text-decoration: line-through;
          margin-left: 8px;
        }

        .coupon-price {
          font-size: 24px;
          color: #fff;
          display: flex;
          align-items: center;
          margin-left: 8px;

          span {
            font-size: 28px;
            font-weight: bold;
          }
        }
      }
    }
  }

  .packgage {
    border-radius: 8px;
    overflow: hidden;
    margin-top: 24px;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.04), 0 2px 6px 0 rgba(0, 0, 0, 0.04);

    .packgage_box {
      background: #d7e5ff;

      .packgage_padding {
        padding: 24px;
        color: rgba(0, 0, 0, 0.8);
        font-size: 32px;

        .packgage_title {
          line-height: 64px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .packgage_title_text {
            max-width: 350px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
          }

          .packgage_title_clamp {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
          }

          span {
            border-radius: 8px;
            background: linear-gradient(180deg,
                rgba(55, 182, 254, 1) 0%,
                rgba(55, 125, 254, 1) 100%);
            color: rgba(241, 245, 251, 1);
            font-size: 32px;
            line-height: 64px;
            padding: 0px 32px;
            letter-spacing: 5px;
          }
        }

        .packgage_day {
          margin-top: 8px;

          span {
            color: rgba(55, 125, 254, 1);
          }
        }

        .packgage_time {
          color: rgba(0, 0, 0, 0.4);
          font-size: 28px;
          margin-top: 8px;
        }

        .packgage_progress {
          margin-top: 8px;
        }
      }
    }

    .packgage_box_free {
      background: #fef0f0 !important;

      .packgage_day {
        span {
          color: #f56c6c !important;
        }
      }

      .packgage_title {
        span {
          background: linear-gradient(180deg,
              rgba(255, 161, 19, 1) 0%,
              rgba(255, 81, 81, 1) 100%) !important;
        }
      }
    }
  }

  .record {
    border-radius: 16px;
    background: rgba(255, 255, 255, 1);
    margin-top: 24px;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.04), 0 2px 6px 0 rgba(0, 0, 0, 0.04);

    .record_box {
      color: rgba(0, 0, 0, 0.8);
      font-size: 32px;

      .record_padding {
        padding: 24px 24px 0px 24px;

        .record_border {
          border-bottom: solid rgba(0, 0, 0, 0.1) 2px;
          padding-bottom: 34px;

          .record_title {
            display: flex;
            justify-content: space-between;

            div {
              max-width: 530px;
            }
          }

          .record_time {
            color: rgba(0, 0, 0, 0.4);
            font-size: 28px;
            margin-top: 8px;
          }
        }
      }
    }
  }

  .teb_logOut {
    font-size: 32px;
    line-height: 100px;
    text-align: center;
    color: rgba(0, 0, 0, 0.8);
    background: #ffffff;
    border-radius: 16px;
    margin-top: 48px;
  }

  .empty {
    color: rgba(0, 0, 0, 0.4);
    font-size: 32px;
    text-align: center;
    padding: 24px;
    background-color: #ffffff;
    border-radius: 16px;
  }
}

.tips {
  font-weight: 400;
  color: #00000063;
  font-size: 32px;
}

.popup_box {
  padding: 0px 50px 48px 50px;
  display: flex;
  flex-direction: column;

  img {
    width: 400px;
    height: 400px;
    margin: auto;
    // margin-top: 50px;
  }

  .popup_title {
    // line-height: 100px;
    color: rgba(0, 0, 0, 0.8);
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    margin: 48px 0px 32px;
  }

  .popup_text {
    text-align: center;
    margin-top: 50px;
  }

  .popup_box_bnt {
    width: 272px;
    margin: auto;
    margin-top: 32px;
  }
}

.product_box {
  margin-top: 48px;

  /deep/ .van-cell {
    padding: 40px 34px 0 0;
    background: #f1f2f3;
    border: none;
  }

  /deep/ .van-collapse-item__content {
    background: #f1f2f3;
    padding: 0;
  }

  /deep/ .van-cell__value {
    color: #aaaaaaff;
    font-size: 24px;
    font-weight: 400;
    font-family: "PingFang SC";
  }

  .commodity_title {
    width: 152px;
    display: flex;
    justify-content: space-between;

    .left img {
      width: 48px;
      height: 48px;
    }

    .center {
      color: rgba(0, 0, 0, 0.8);
      font-size: 40px;
      font-weight: 700;
      line-height: 56px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .coupon_goods_list {
    width: 690px;
    border-radius: 12px;
    margin: 20px auto;
    opacity: 1;
    background: #ffffffff;

    .list_content {
      min-height: 104px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;

      .left {
        padding-left: 20px;

        font: {
          size: 30px;
          weight: 400;
        }

        font-family: "PingFang SC";
        color: #333333;
      }

      .right {
        padding-right: 20px;

        .van-button--primary {
          min-width: 150px;
          min-height: 58px;
          border-radius: 16px;
          border: none;
          opacity: 1;
          background: linear-gradient(139deg, #ff974bff 0%, #fe3e14ff 100%);
        }

        span {
          font: {
            size: 30px;
            weight: 400;
          }

          font-family: "PingFang SC";
          color: #fff;
        }
      }
    }
  }
}

.banner {
  padding: 48px 32px 0px 32px;

  img {
    width: 688px;
    height: 100%;
  }
}

.positionBottom {
  // position: absolute;
  // left: 0;
  // bottom: 0;
  // z-index: 1;
  // width: 100%;
  // height: 50px;
}

.count-down {
  bottom: 0px;
  left: 0px;
  background: red;
  color: #ffffff;
  font-size: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 50px;

  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 50px;

  .count-down-child {
    color: #ffffff;
    // font-size: 13px;
  }
}

.rechargeTraffic {
  color: rgba(0, 0, 0, 0.8);
  font-size: 28px;
  padding: 24px;
  background-color: #ffffff;
  border-radius: 16px;
  margin-top: 20px;
}

.wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1989fa;
  z-index: 10;

  .wrapper-box-text {
    margin-top: 30px;
    font-weight: 700;
  }
}

.notice {
  border-radius: 16px;
  overflow: hidden;
  margin-top: 20px;
}

.notice-swipe {
  height: 40px;
  line-height: 40px;
}

.balanceRecharge {
  line-height: 96px;
  border-radius: 16px;
  color: #6c4a0acc;
  font-size: 36px;
  background: linear-gradient(135deg, #ffe59dff 0%, #ffedc0ff 49%, #ffd177ff 100%);
  text-align: center;
  margin-top: 20px;

  span {
    color: #6c4a0aff;
    font-weight: 700;
  }
}

.realNameBox {
  display: flex;
  flex-direction: column;
  width: 615px;
  margin: 0 auto;
  height: 300px;
  .appbar {
    // @include border-1px(#eeeeee, bottom);
    border-bottom: 1px solid #eeeeee;
    height: auto;
    .van-nav-bar__title,
    .van-nav-bar .van-icon {
      color: inherit;
    }
  }
  ::after {
    background: #2196f3 !important;
  }
  .realNameContent {
    flex: 1;
    overflow: auto;
    .realNameTitle {
      background: #ffffff;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      text-align: center;
      height: 200px;
      line-height: 200px;
    }
    .realNameChoice {
      height: 98px;
      background: #ffffff;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      display: flex;
      text-align: center;
      line-height: 98px;
      justify-content: center;
      .realNameCancel {
        border-top-color: #f2f2f2;
        border-top-style: solid;
        border-top-width: 1px;
        border-right-color: #f2f2f2;
        border-right-style: solid;
        border-right-width: 1px;
        width: 300px;
        color: #aaaaaa;
      }      
      .realNameConfirm {
        border-top-color: #f2f2f2;
        border-top-style: solid;
        border-top-width: 1px;
        border-left-color: #f2f2f2;
        border-left-style: solid;
        border-left-width: 1px;
        width: 300px;
        color: #0bb20c;
      }
    }
  }
}
</style>
